<template>
  <div class="home-container pt-0">
    <div class="slider-home-container main-content col-12 px-0 mx-auto">
      <Slider></Slider>
    </div>
    <div class="main-content games-content col-12 mx-auto py-3 px-2 px-md-0 px-xl-3">
      <Games></Games>
    </div>
  </div>
</template>

<script>
import Slider from '@/components/main/Slider'
import Games from './games/Games'

export default {
  name: 'Home',
  components: {
    Slider,
    Games,
  },
  computed: {
    winners () {
      return this.$store.getters.getWinnersList
    }
  },
  mounted() {
    window.scrollTo({
      top: 0
    })
    this.$store.dispatch("checkForAliveGame")
    this.$store.dispatch("checkUserFreeRound")

  }
}
</script>
