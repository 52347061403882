<template>
  <div>
    <Header></Header>
    <div id="main-content" class="content-block pb-5">
      <router-view></router-view>
    </div>
<!--    <div class="page-up" @click="scrollTop">-->
<!--      <span><i class="fa fa-scroll-up"></i></span>-->
<!--    </div>-->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/main/Header'
import Footer from '@/components/main/Footer'

export default {
name: "MainVersionLoader",
  components: {
    Header,
    Footer,
  },
  methods: {
    scrollTop () {
      window.scrollTo({ top: 0 })
    },
    isMobile () {
      return this.$_config.environment.mobile
    }
  },
}
</script>

<style scoped>

</style>
