<template>
  <b-modal
    v-if="!userData"
    id="recoverAccountModal"
    ref="recoverAccountModal"
    size="md"
    modal-class="main-modal page-main-block"
    ok-title="Закрыть"
    ok-variant="success modal-ok"
    header-class="text-white border-0 pb-0"
    title-class="text-center underlined-muted-title"
    footer-class="border-0"
    hide-footer
    body-class="pb-2 pt-1 px-3 "
    centered
    title="Восстановление пароля"
  >
    <div class="recover-account-area col-12 px-0 py-2">
      <div class="col-12 mt-2 px-0 mx-auto">
        <b-tabs content-class="" class="tab pass-recover-tab px-0">
          <b-tab class="tab-item" active>
            <template v-slot:title>
              <span v-if="isMobile">
                <i class="fa fa-envelope" />
              </span>
              <span v-else>По Е-mail</span>
            </template>
            <recover-by-email />
          </b-tab>
          <b-tab class="tab-item" v-if="mainSettings && mainSettings.phoneActionsMethod === 'call'">
            <template v-slot:title>
                <span v-if="isMobile">
                  <i class="fa fa-phone-square" />
                </span>
              <span v-else>По номеру телефона</span>
            </template>
            <recover-by-call/>
          </b-tab>
          <b-tab class="tab-item" v-else>
            <template v-slot:title>
              <span v-if="isMobile">
                <i class="fa fa-phone-square" />
              </span>
              <span v-else>По номеру телефона</span>
            </template>
            <recover-by-sms />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </b-modal>
</template>

<script>
import RecoverByEmail from './RecoverViaEmail'
import RecoverBySms from './RecoverViaSms'
import RecoverByCall from './RecoverViaCall'

export default {
  name: 'ForgotPassword',
  components: {
    RecoverByEmail,
    RecoverBySms,
    RecoverByCall
  },
  computed: {
    userData () {
      return this.$store.getters.userData
    },
    isMobile () {
      return this.$_config.environment.mobile
    },
    mainSettings () {
      return this.$store.getters.getMainSettings
    },
  },
  watch: {
    userData (newValue) {
      if (newValue) {
        this.$router.push({ name: 'home' })
      }
    }
  },
  mounted () {
    if (this.userData) {
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style>
    #recoverAccountModal .nav-tabs{
        border-bottom: unset;
    }
    #recoverAccountModal .tab-content{
        background-color: #505050;
        border-top: unset;
    }
    #recoverAccountModal .nav-item{
        width: 50%;
        text-align: center;
        margin-bottom: 0;
        border-radius: unset;
    }
    #recoverAccountModal .modal-dialog{
        background-color: transparent;
    }
    #recoverAccountModal .nav-link{
        color:#848484;
        border-radius: unset;
        padding: 15px 20px;
        border: 1px solid #505050;
    }
    #recoverAccountModal .nav-link:hover{
        color:#9a9898;
    }
    #recoverAccountModal .nav-link.active{
        background-color: #505050;
        border :unset;
        height: 100%;
        color: #aba9a9;
    }
    #recoverAccountModal .modal-header .close {
        margin: 0 !important;
    }
</style>
<style scoped>

</style>
