<template>
  <div class="account-container py-4 col-12 col-lg-11 col-xl-10  mx-auto mb-5">
    <div class="col-12 page-router mt-4">
      <h6 class="text-white w-100 text-center">
        Главная /
        <span class="active-router-text">Профиль</span>
      </h6>
    </div>
    <h4 class="w-100 text-center text-white my-3 my-lg-5 page-main-title">
      Профиль
    </h4>

    <div class="col-12 mx-auto text-center my-5 py-3 page-main-block px-0 px-lg-3">
      <div class="col-12 col-sm-12 col-md-12 mt-3 mt-lg-3 mx-auto px-0">
        <b-tabs
            content-class="mt-0 mt-lg-3"
            class="tab account-tab px-0"
            nav-class="col-12 col-lg-8 mx-auto px-0">
          <b-tab class="account-tab-item col-12 px-0 py-4" title="Ваш профиль" active>
            <profile></profile>
          </b-tab>
          <b-tab class="tab-item" title="Письма">
            <messages></messages>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Profile from '@/components/user/Profile'
import Messages from '@/components/user/Messages'

export default {
  components: {
    Profile,
    Messages
  },
  computed: {
    userData() {
      return this.$store.getters.userData
    }
  },
  mounted() {
    setTimeout(() => {
      if (!this.userData) {
        this.$router.push({name: 'login'})
      }
    }, 1000)
  }
}
</script>

<style>
.account-tab .nav-tabs {
  border: none !important;
  margin-bottom: 30px;
  justify-content: center;
}

.account-tab .nav-tabs .nav-item {
  width: 33% !important;
  border: none !important;
  margin: 0;
}

.account-tab .nav-tabs .nav-item .nav-link {
  color: white !important;
  background-color: rgba(0, 0, 0, 0.3);;
  border: none;
  padding: 8px 2px;
}

.account-tab .nav-tabs .nav-item .nav-link.active {
  background-color: #950101;
  color: white !important;
  padding: 8px 3px;
}

.account-tab .nav-tabs .nav-item .nav-link {
  border-radius: 3px;
  margin: 0 15px;
  text-align: center;
}

.account-tab .nav-tabs .nav-item:first-child .nav-link::before {
  margin-right: 10px;
}

.account-tab .nav-tabs .nav-item:last-child .nav-link::before {
  margin-right: 10px;
}

.account-tab-item {
  background-color: transparent;
}

@media (min-width: 320px) and (max-width: 768px) {
  .account-tab .nav-tabs .nav-item {
    margin-bottom: 10px;
  }

  .account-tab .nav-tabs .nav-item .nav-link {
    margin: 0 5px;
  }

  .account-tab .nav-tabs {
    margin-bottom: 0;
  }

  .account-tab .nav-tabs .nav-item {
    width: 45% !important;
  }
}
</style>
