<template>
    <div class="col-12">
        <h1 class="text-center">Terms And Conditions</h1>
        <div class="col-10  mx-auto px-0">
            <a href="#" class="btn btn-secondary print_this_page" @click.prevent="onPrintPage">Print this page </a>

            <div class="notification__desc">
                <p><strong><u>General Terms and Conditions</u></strong></p>

                <p>&nbsp;</p>

                <p><strong>1. Entry</strong></p>

                <p>These Terms and Conditions constitute the agreement between zevs777.com (hereinafter "Casino") and you (hereinafter "you" or "Gambler").</p>

                <p>1.1.1. &nbsp;&nbsp; The General Terms and Conditions on this page,</p>

                <p>1.1.2. &nbsp;&nbsp; Privacy Policy,</p>

                <p>1.1.3. &nbsp;&nbsp; Responsible Gaming,</p>

                <p>1.1.4. &nbsp;&nbsp; Any promotions, bonuses, special promotional terms and conditions that may be updated from time to time that you may find on our website.</p>

                <p>1.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All of the rules and conditions listed above are all called "Rules".</p>

                <p>1.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; These Terms and Conditions entered into force on 1<sup>st</sup>&nbsp;of January 2021.</p>

                <p>1.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You are required to read them before you start using the services of the Casino. If you do not agree to these Terms and Conditions, you are not allowed to use the services of the Casino.</p>

                <p>1.5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Registration at and visits to the Casino are a confirmation of your agreement with these Terms and Conditions.</p>

                <p>1.6. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Casino reserves the right to amend these terms at any time, with notification of you about it, or without it.</p>

                <p>1.7. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Amendments are deemed effective immediately upon posting on the Casino website. Using the services of the Casino after the publication of the amendments is a confirmation of your acceptance of these amendments.</p>

                <p>&nbsp;</p>

                <p><strong>2. Parties</strong></p>

                <p>2.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </p>

                <p>&nbsp;</p>

                <p><strong>3. Rule Changes</strong></p>

                <p>3.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Commercial rules may be changed by us for a number of reasons, such as changes in laws and regulations or customer service. The current usage of rules can be accessed via the website and the date of entry into force is specified here</p>

                <p>3.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Changes to the terms of use will be communicated to you by email or by posting on the website.</p>

                <p>&nbsp;</p>

                <p><strong>4. Creating an Account</strong></p>

                <p>4.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You must create an account in order to bet and play games through the website.</p>

                <p>4.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; For some legal and commercial reasons, it is not allowed to create an account in some countries and to use websites. It is not allowed to open accounts in these areas and you should not use the website.</p>

                <p>4.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You can get help from the support line to be the member, or you can create an account by following the steps in the menu by clicking here.</p>

                <p>4.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When you create an account, you will be asked for your contact information, which includes your name, date of birth, as well as address phone and e-mail information ("<strong>Contact Information</strong>").</p>

                <p>4.5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You agree that you may earn and lose money by using the website's services.</p>

                <p>4.6. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Your account must be created with your own name and correct information. Each member can only have one account. Any other account you create on our site will be considered as multiple records. Your multiple accounts can be instantly closed and:</p>

                <p>4.6.1. all transactions made by more than one account are considered invalid;</p>

                <p>4.6.2 all deposits made and bets will be returned back to you; and</p>

                <p>4.6.3. If you have more than one account active, all accrued benefits, bonuses may be lost and received from you, you may be asked to return your withdrawals as well.</p>

                <p>4.7. If you want to create another account, you should contact with support@Magnet.bet. The old account will be closed when a new account is created.</p>

                <p>4.8. You should keep your account information protected and up-to-date.</p>

                <p>&nbsp;</p>

                <p><strong>5. Authentication and the prevention of monetary policy</strong></p>

                <p>5.1.&nbsp;&nbsp;&nbsp;&nbsp;The following materials are warranted by you:</p>

                <p>5.1.1.&nbsp;&nbsp;&nbsp;&nbsp;Age limit:</p>

                <p>5.1.1.1.&nbsp;&nbsp;&nbsp;&nbsp;Older than 18; or</p>

                <p>5.1.1.2.&nbsp;&nbsp;&nbsp;&nbsp;the age of legal betting and gambling in your country is greater than your age; and</p>

                <p>5.1.2.&nbsp;&nbsp;&nbsp;&nbsp;the information that you use to create your account is correct; and</p>

                <p>5.1.3.&nbsp;&nbsp;&nbsp;&nbsp;The money that you have in your account belongs to you.</p>

                <p>5.2.&nbsp;&nbsp;&nbsp;&nbsp;By accepting the rules, you allow your personal information and contact information to be checked by us or, if necessary, by third parties, if needed.</p>

                <p>5.3.&nbsp;&nbsp;&nbsp;&nbsp;When checking your account information, you may be restricted from withdrawing funds from your account.</p>

                <p>5.4.&nbsp;&nbsp;&nbsp;&nbsp;In some cases, you may be contacted for authentication and you may be asked to provide information. Casino is entitled to perform “Know your customer” policy in a range and scope at its discretion in order to secure your identity. If requested information is not provided, your account can be suspended or permanently closed until the requested information is provided. You may be asked for your credentials if the amount you want to withdraw is one thousand euros (EUR 1,000) or more.</p>

                <p>5.5.&nbsp;&nbsp;&nbsp;&nbsp;Your account will be suspended if it is confirmed that you are over legal age but you are proved to be below the legal age limit while you are playing a game(gambling):</p>

                <p>5.5.1.&nbsp;&nbsp;&nbsp;&nbsp;Your account is closed;</p>

                <p>5.5.2.&nbsp;&nbsp;&nbsp;&nbsp;all transactions you have made while under legal age limit will be cancelled and all your money payments will be refunded;</p>

                <p>5.5.3.&nbsp;&nbsp;&nbsp;&nbsp;all bets you play are cancelled; and</p>

                <p>5.5.4.&nbsp;&nbsp;&nbsp;&nbsp;all winning bets are cancelled and if withdrawals are made, withdrawals are requested back.</p>

                <p>&nbsp;</p>

                <p><strong>6. Username, Password and Customer Information</strong></p>

                <p>6.1.&nbsp;&nbsp;&nbsp;&nbsp;After creating an account, the username and password must not be disclosed intentionally or unintentionally. If you lose your password or forget it, you can get your password information by clicking "Forgot Password" link in the login section.</p>

                <p><br><strong>7. Deposit Your Account and Withdrawal from Your Account</strong></p>

                <p>7.1&nbsp; &nbsp; &nbsp; &nbsp;Withdrawal from the gaming account can be made in any way available at the Casino website at the time of replenishment</p>

                <p>The Casino reserves the right to transfer funds in a way different from the payment method specified by the Gambler.</p>

                <p>The period of processing requests for withdrawals is from 0 to 24 hours.</p>

                <p>Date of receipt of money on the Gambler's accounts outside of the Casino depends exclusively on banks and payment systems, to accounts of which the funds are withdrawn.</p>

                <p>In case of necessity to verify your identity, the time of processing the requests is calculated from the date of verification of the documents requested.</p>

                <p>The minimum withdrawal amount from the gaming account per transaction is EUR 20 / USD 20 / AUD 50 / CAD 30 / NOK 200 / SEK 200 / RUB 1,500 / PLN 80 / NZD 30 / BTC 0.002.</p>

                <p>The maximum withdrawal amount from the gaming account per transaction is EUR 4,000 / USD 4,000 / AUD 6,000 / CAD 6,000 / NOK 40,000 / SEK 40,000 / RUB 200,000 / PLN 16,000 / NZD 6,000 / BTC 0.4, except YandexMoney, Comepay, Sberbank Online, Mobile Commerce, Svyaznoy, Euroset, QIWI.</p>

                <p>The maximum withdrawal amount from the gaming account per transaction for Yandex Money, Comepay, Sberbank Online, Mobile Commerce, Svyaznoy, Euroset, QIWI is EUR 200 / USD 200 / RUB 15,000.</p>

                <p>The daily limit for funds withdrawal is EUR EUR 4,000 / USD 4,000 / RUB 200,000 / AUD 6,000 / CAD 6,000 / NOK 40,000 / SEK 40,000 / RUB 200,000 / PLN 16,000 / NZD 6,000 / BTC 0.4.</p>

                <p>The weekly limit for funds withdrawal is EUR 6,000 / USD 6,000 / AUD 9,000 / CAD 9,000 / NOK 60,000 / SEK 60,000 / RUB 300,000 / PLN 24,000 / NZD 9,000 / BTC 0.6.</p>

                <p>The monthly limit for funds withdrawal is EUR 15,000 / USD 15,000 / AUD 22,500 / CAD 22,500 / NOK 150,000 / SEK 150,000 / RUB 750,000 / PLN 60,000 / NZD 22,500 / BTC 1.5.</p>

                <p>7.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Deposits and withdrawals can be made with various payment systems. Procedures, rules and conditions vary depending on the payment system. Cash payments are not accepted by us.</p>

                <p>7.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Your account is not a bank account and does not have guarantees, insurance or sponsorships, is not included in any banking insurance system, and it is not possible to earn interest income from the deposits.</p>

                <p>7.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We reserve the right to recalculate your account and the amount of bets made, regardless of the amount gained, for more than one account, confidential agreement, fraud, illegal activity or any mistakes that might occur.</p>

                <p>7.5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; It is your responsibility to inform your local tax authorities and competent authorities of your earnings and losses.</p>

                <p>7.6. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You can withdraw funds from your account at any time by providing the following conditions:</p>

                <p>7.6.1. &nbsp;&nbsp; all deposits made to your account must be clean and there should be no refund request or objection otherwise the payment request will be cancelled;</p>

                <p>7.6.2.&nbsp;&nbsp;&nbsp; All controls specified in Article 7.4 should be completed.</p>

                <p>7.7. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Once your withdrawal request is approved by you, you must provide all necessary information regarding the transfer of funds. The payment system and currency you choose will attempt to make your money transfer request, but cannot be guaranteed.</p>

                <p>7.8. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We reserve the right to charge a fee of its own costs (including the cost of deposits) to withdraw money not poured into the game.</p>

                <p>7.9.1 &nbsp;&nbsp;&nbsp; Members who are registered with zevs777.com must deposit funds into their account.</p>

                <p>7.9.2. &nbsp;&nbsp; Payments made by a third party, friend, relative, spouse or family are not accepted.</p>

                <p>7.9.3. &nbsp;&nbsp; If it is noticed during routine security checks, all casino winnings will be returned to the account/credit card holder.</p>

                <p>7.9.4. &nbsp;&nbsp; If bank transfer is required for reimbursement by the account holder, the bank will be charged by the buyer.</p>

                <p>7.9.5. &nbsp;&nbsp; If Mastercard refunds are not possible, the cardholder must fill out a 'credit card verification form' and send us for identification documents and confirmation.</p>

                <p>7.9.6. &nbsp;&nbsp; All credit card payments can take up to 5 days of processing time for VISA and MasterCard or any other payment provider.</p>

                <p>7.9.7.&nbsp; &nbsp; The commission established by the payment system is payed by player.</p>

                <p>&nbsp;</p>

                <p><strong>8. Playing (Gambling)</strong></p>

                <p>8.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All processing continues with the language in which the operation is started.</p>

                <p>8.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; It is your responsibility to ensure that the transaction details are correct</p>

                <p>8.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You can access your transaction history via the website.</p>

                <p>8.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We reserve the right, at any time, to terminate the proceedings in whole or in part at our discretion. Transactions are not valid unless they are approved by us. If you have not received a confirmation email confirming the transaction has been approved, contact your support.</p>

                <p>8.5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Once a bet is confirmed, the transaction cannot be cancelled without our written consent.</p>

                <p>&nbsp;</p>

                <p><strong>9. Secret Agreement, Fraud, Fraud and Illegal Activities</strong></p>

                <p>9.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The following activities are prohibited on our system:</p>

                <ul><li>making secret agreements with third parties;</li>
                    <li>using an advantage or effect that will create injustice (making cheat in general), exploiting a possible mistake, using software deficiencies or mistakes, using automatic players (also called 'bots');</li>
                    <li>use credit, debit cards that have been seized, stolen, copied, or otherwise fraudulently used; taking part in or being a part of money laundering or similar offenses.</li>
                </ul><p>9.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The necessary steps are taken by us to prevent, detect and determine the related actors. Our company is not responsible for any damage or loss that you or any other player may suffer from undercover agreements, fraud, fraud and illegal activities, and your actions are at the discretion of our company.</p>

                <p>9.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If you feel that someone has done secret deal, fraud, fraud or illegal activity, you should let us know by e-mail.</p>

                <p>9.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; As a result of any suspicious illegal or inappropriate activity, we reserve the right to share your credentials with the authorities, other gaming and betting sites and operators, online service providers and banks, credit card companies, electronic payment system providers and other financial institutions, and we reserve the right to cooperate in the investigation of such activities you agree.</p>

                <p>&nbsp;</p>

                <p><strong>10. Other Prohibited Activities</strong></p>

                <p>10.1. &nbsp;&nbsp;&nbsp; You should not use the website services and chat functionality for any purpose that is considered insulting, obscene, racist, discriminatory or offensive. You should not use an offensive or offensive language or image, and should not be disruptive to other users or website employees.</p>

                <p>10.2. &nbsp;&nbsp;&nbsp; You must not engage in any attempt to circumvent the operation of the website, and should not use viruses, worms, or other methods that will affect the services of the website. It is strictly forbidden to send bulk mail or spam at any time. You should not try to change or interfere with any information in any form on the website.</p>

                <p>10.3. &nbsp;&nbsp;&nbsp; The website must be used for entertainment purposes only and is prohibited from being copied or used without permission of the website.</p>

                <p>10.4. &nbsp;&nbsp;&nbsp; You should not try to access the website, the servers, the computers, and the databases on which the website is located unless you are competent. You should not be attacked with DDOS (denial of service) or similar systems. In case you are involved in such initiatives, business partnerships will be made with the relevant authorities and your identity information will be shared. Your right to use the website at the end of such an attempt is immediately terminated.</p>

                <p>10.5. &nbsp;&nbsp;&nbsp; Our company is not responsible for any loss or damage to your documents that may occur in your computer, computer programs, etc., while using the website, file downloading virus, DDOS attack or various harmful technologies from the website.</p>

                <p>10.6. &nbsp;&nbsp;&nbsp; It is forbidden to sell or transfer accounts between members.</p>

                <p><br><strong>11. Account Termination and Termination</strong></p>

                <p>11.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; An inactive account is an account of the player which a player has not logged into or logged out of for 12 (twelve) consecutive months (“Player Account”). If your Player Account is deemed to be inactive, the Casino reserves the right to charge a monthly administrative fee of €10 or the equivalent in another currency (or the current balance of your Player Account, if less) as long as the balance of your Player Account remains positive.</p>

                <p style="margin-left:35.45pt;">You authorize the Casino to debit this fee from your Player Account on the beginning of the month following the day on which your Player Account is deemed inactive, and on the beginning of every subsequent month that your Player Account remains inactive. The Casino will stop deducting the fee if the account balance is zero or if the account is re-activated.</p>

                <p><br><strong>12. Website Updates</strong></p>

                <p>12.1. &nbsp;&nbsp;&nbsp; Any change or update of any service on the website is at the discretion of our company at any time.</p>

                <p><br><strong>13. Information Technology Errors</strong></p>

                <p>13.1. &nbsp;&nbsp;&nbsp; We will be briefly intervened to resolve the problem if there is any software or hardware failure or malfunction in the system providing the website.</p>

                <p>13.2. &nbsp;&nbsp;&nbsp; We are not responsible for any errors in hardware or software you use or access errors caused by your Internet service provider.</p>

                <p>&nbsp;</p>

                <p><strong>14. Other Faults</strong></p>

                <p>14.1. &nbsp;&nbsp;&nbsp; Due to various reasons, a gaming transaction originated and incorrectly accepted by us may have been accepted or paid.</p>

                <p>&nbsp;</p>

                <p><strong>15. Responsibilities</strong></p>

                <p>15.1. &nbsp;&nbsp;&nbsp; Accessing the website and using the services is entirely your choice and discretion and all risks are yours.</p>

                <p>15.2. &nbsp;&nbsp;&nbsp; Web: A user-friendly website is presented, as defined in the rules. No warranties or promises are made for any services / products other than those offered on our website and at our web site. The warranty applied to similar products hereunder is outside our guarantee to the extent permitted by law.</p>

                <p>15.3. &nbsp;&nbsp;&nbsp; Our company is not responsible for loss of business, data loss, profit, income, opportunity, prestige, reputation and business interruption and unpredictable losses and is excluded from the contract.</p>

                <p>&nbsp;</p>

                <p><strong>16. Terms of Use Violations</strong></p>

                <p>16.1. &nbsp;&nbsp;&nbsp; You are responsible for any liability, costs, expenses (including legal fees) and any costs incurred as a result of breach of these rules.</p>

                <p>16.2. &nbsp;&nbsp;&nbsp; If you violate the terms of use, the rights are reserved but:</p>

                <p>16.2.1. You will be informed that your activity is contrary to the rules and that you need to stop using your contact information,</p>

                <p>16.2.2. your account is frozen and blocked from gaming (gambling),</p>

                <p>16.2.3. your account will be closed without giving you the preliminary information.</p>

                <p>16.2.4. withdrawals, earnings and bonuses affected by any violation of the rules are withdrawn.</p>

                <p>16.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If the rules are not followed, the user code and password are disabled at the company's disposal.</p>

                <p>&nbsp;</p>

                <p><strong>17. Intellectual Property Rights</strong></p>

                <p>17.1. &nbsp;&nbsp;&nbsp; All website design, text, graphics, music, sound, photographs, video, selection and editing thereof, software compilations, source code, software and all materials are subject to the copyrights of our copyrights or other proprietary rights used by third party rights holders. Materials located on the website may be downloaded solely for personal use on a single personal computer and may not be used for commercial purposes.</p>

                <p>17.2. &nbsp;&nbsp;&nbsp; There is no question that the rights of us or third party entitlements are transferred to any user.</p>

                <p>17.3. &nbsp;&nbsp;&nbsp; The use and reproduction of trademarks, trade names and logos appearing on the website is prohibited unless permitted by law.</p>

                <p>&nbsp;</p>

                <p><strong>18. Use of Cookies</strong></p>

                <p><br>
                    When you visit the Website, our system automatically collects information about your visit, such as your browser, IP address, and the referring website. This collection may be done in conjunction with our platform providers and partners. We may receive from them general demographic or usage data of our Website visitors. We do not use automatically collected information to identify you personally without receiving additional consent.</p>

                <p>To collect the information in question we use cookies and similar tracking tools. Cookies are small text files that are stored on your computer or equipment when you visit our web pages. Some of the cookies are essential for the Website to operate; others improve your Website experience and help us deliver a better service. Below are the types of cookies we use and their purposes.</p>

                <p><strong>REQUIRED COOKIES:&nbsp;</strong>enable the navigation and basic functionality of the websites, e.g., access to member areas of the Website.</p>

                <p><strong>FUNCTIONAL COOKIES:&nbsp;</strong>allow us to analyse your website usage and your selections on the website (e.g. your session key, language, or region), so we can save these settings and offer you a more personalised experience.</p>

                <p><strong>ADVERTISING COOKIES:&nbsp;</strong>allow us to gauge how effective our content marketing is. These cookies are provided by our partners to track website visits and new player registrations from advertising. We do not share your personal information (such as name or email) to affiliated partners except for site visit data collected directly by such Advertising Cookies. However your site visit data may be linked with other personal information collected through other sources by the providers. The latter external data processing is governed by the privacy notices and policies of these third-party providers.</p>

                <p>In addition to the above, we use a number of third party service providers who also set cookies on this Website, in order to deliver the services that they are providing to us. Such services include, but are not limited to, helping us to improve your experience by tracking your activity on the Website, measuring the effectiveness of the Website and the effectiveness of our marketing campaigns.</p>

                <p>Most online browsers automatically accept cookies. If you prefer, it is possible to block some or all cookies, or to delete cookies that have already been set by modifying your browser settings. However, we recommend that you do not block or delete your cookies as this may restrict your use of our Website.</p>

                <p>&nbsp;</p>

                <p><strong>19. Complaints and Notices</strong></p>

                <p>19.1. &nbsp;&nbsp;&nbsp; In order to make any comments about the website, you should first go through the basics with the customer relations unit.</p>

                <p>19.2. &nbsp;&nbsp;&nbsp; In the event of any dispute, you agree that server records will be recognized as the ultimate authority in determining the outcome of any claim.</p>

                <p>19.3. &nbsp;&nbsp;&nbsp; If there is any difference between the result on the user screen and the result on the game server, the data on the game server will be valid. You acknowledge that we are the ultimate authority to set the rules and conditions during your participation in online games.</p>

                <p>19.4. &nbsp;&nbsp;&nbsp; In case of any conflict, your contact information will be used to communicate with you.</p>

                <p><br><strong>20. Limitation of liability</strong></p>

                <p>20.1. &nbsp;&nbsp;&nbsp; You agree that you are free to choose whether to use the services on the Website and do so at your sole option, discretion and risk.</p>

                <p>20.2. &nbsp;&nbsp;&nbsp; We will provide the Website with reasonable skill and care and substantially as described in the Terms. We do not make any other promises or warranties regarding the Website or the products offered via the Website and hereby exclude (to the full extent permitted by the law) all implied warranties in this respect.</p>

                <p>20.3. &nbsp;&nbsp;&nbsp; The Company shall not be liable in contract, tort, negligence, or otherwise, for any loss or damage, including but not limited to the loss of data, profits, business, opportunities, goodwill or reputation as well as business interruption or any losses which are not currently foreseeable by us arising from or in any way connected with your use, of any link contained on the Website. The Company is not responsible for the content contained on any Internet site linked to from the Website or via the services.</p>

                <p>&nbsp;</p>

                <p><strong>21. Revocation of Rights and Obligations</strong></p>

                <p>21.1. &nbsp;&nbsp;&nbsp; We reserve the right to assign, assign, sublicense, or pledge any other person in a way that does not give you the same or less advantage.</p>

                <p>&nbsp;</p>

                <p><strong>22. Events Outside Control</strong></p>

                <p>22.1. &nbsp;&nbsp;&nbsp; Our company can not be held responsible for cases where warnings, social insurrection, interruptions in network services, industrial troubles and DDOS attacks, and other compelling reasons are not fulfilled due to incidents outside the control of our website or service delays. During the course of these compelling reasons, our services will be deemed suspended and our serving time will be extended for the duration of the compelling reasons. Our site will make every effort to reach a settlement to terminate these compelling reasons or to fulfill their obligations as far as possible, despite compelling reasons.</p>

                <p>&nbsp;</p>

                <p><strong>23. Disclaimer</strong></p>

                <p>23.1. &nbsp;&nbsp;&nbsp; The failure of our site to warn you if you neglect your obligations as a user or the disruptions that may arise in implementing our rights and regulations that our site is authorized do not and will not remove your obligation to these rights and obligations.</p>

                <p>23.2. &nbsp;&nbsp;&nbsp; Failure to comply with any obligations arising from you will not constitute a waiver of any obligation arising therefrom. No waiver of the terms of use has been made unless expressly stated to you and notified in writing to you.</p>

                <p>&nbsp;</p>

                <p><strong>24. Divisibility</strong></p>

                <p>24.1. &nbsp;&nbsp;&nbsp; If any of the rules are found to be invalid, illegal or unenforceable, these rules will be removed from the other rules, and the remaining rules will remain in effect to the extent permitted by law. In such cases, invalid or unenforceable parts shall be amended as appropriate and in accordance with the original text.</p>

                <p>&nbsp;</p>

                <p><strong>25. Law and Justice</strong></p>

                <p>25.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; These Terms and Conditions are subject to and construed in accordance with the laws of the Netherlands Antilles, and you irrevocably submit to the exclusive (single) law of the courts of the jurisdiction of the Netherlands Antilles for the settlement of any disputes (including claims for compensation and counterclaims) which may arise in connection with creation, legitimacy, effect, interpretation or action, or the legal relationship established by the Terms and Conditions or in any way arising from them.</p>

                <p>&nbsp;</p>

                <p><strong>26. Responsible Gaming(Gambling) And Betting</strong></p>

                <p>26.1. &nbsp;&nbsp;&nbsp; For users who want to restrict themselves, there is a voluntary self-restriction policy that allows you to close your account or limit your betting. Please contact the customer relationship department to learn about this service</p>

                <p>26.2. &nbsp;&nbsp;&nbsp; You will be assisted in using the self-restraint service. However, any responsibility will not be accepted by us if we are unable to identify or notice your usage of the website to play the bet.</p>

                <p>26.3. &nbsp;&nbsp;&nbsp; We are committed to supporting Responsible Gaming entrepreneurs, and we encourage you to visit the following sites to learn about the Problem with Gaming:</p>

                <p style="margin-left:35.45pt;">Gambling Therapy - www.gamblingtherapy.org Gordon House Association - www.gordonhouse.org.uk</p>

                <p style="margin-left:35.45pt;">Gordon House Association - www.gordonhouse.org.uk</p>

                <p>&nbsp;</p>

                <p><strong>27. Links</strong></p>

                <p>27.1. &nbsp;&nbsp;&nbsp; All other sites linked on our site are informational. Use of these links is your responsibility and we are not responsible for any problems that may arise from the use or content of these sites.</p>

                <p>&nbsp;</p>

                <p><strong>28. Bonus</strong></p>

                <p>28.1. &nbsp;&nbsp;&nbsp; Bonuses are promotions for our members. Our bonuses are only available to members with unique IP. For this reason, do not get a bonus if you are accessing the website from shared networks like internet cafe, lodging, cafe, workplace, dormitory. Otherwise, no liability will be accepted.</p>

                <p>28.2. &nbsp;&nbsp;&nbsp; If you have more than one membership from the same IP address, all your bonuses and winnings will be CANCELLED.</p>

                <p>28.3. &nbsp;&nbsp;&nbsp; Only one bonus can be used from the depositing money.</p>

                <p>&nbsp;</p>

                <p><strong>29. Bonus money summary</strong></p>

                <p>You may be awarded bonuses or bonus money when You register to join our casino or during your time as a member of our casino. You are able to opt-out of receiving all bonuses from Us or any specific one at any time by contacting Our Customer Services department at&nbsp;support@Magnet.bet&nbsp;or through our live chat or through user interface in My Profile page. It is important that You fully understand the terms of each bonus offer that you participate in. This section contains the general terms and conditions associated with all bonuses within our casino. In addition, each bonus offer may also have supplementary terms and conditions that will be provided when You are invited to participate in the offer.</p>

                <p><strong>29.1.&nbsp;</strong>&nbsp;&nbsp;&nbsp;&nbsp;<strong>Bonuses – the details</strong></p>

                <p>All bonuses are based on the following rules. When you have a bonus on your account:</p>

                <p>For the first deposit bonus maximum bet is equal for 250 RUB.</p>

                <p>The bonus amount will be placed into a bonus balance and will be kept separate from your cash balance.</p>

                <p>When you place a bet, the bet will be deducted from your cash balance. If there are no funds remaining in your cash balance, then bets will be deducted from your bonus balance.</p>

                <p>Any winnings that you receive will be placed on to your bonus balance and cannot be withdrawn until you have met the wagering requirements for that bonus.</p>

                <p>The bonus amount itself may also not be withdrawn until you have met the wagering requirements. In some cases the bonus is non-redeemable, in which case the bonus amount can never be withdrawn.</p>

                <p>When you have met the wagering requirements, the sum in your bonus balance that is linked to the active bonus will be transferred into your cash balance and may then be withdrawn at any time.</p>

                <p>Not all bets will count towards wagering requirements, for example low risk roulette bets. See below for more details.</p>

                <p>Please ensure you read all other terms below that relate to bonuses.</p>

                <p>Only one bonus can be awarded for any specific event unless We state otherwise, and only one bonus can be active on your account at any time.</p>

                <p><strong>29.2. &nbsp;&nbsp;&nbsp; Your cash balance and your bonus balance</strong></p>

                <p>A bonus is considered as a 'free bet' and it does not have an equivalent cash value. No cash alternative, substitution, transfer or assignment of any bonus will be allowed at any time other than as set out in these terms.</p>

                <p>When you are awarded a bonus, it will be added to the "<strong>Bonus Balance</strong>" in your account.</p>

                <p>You cannot withdraw any sums in your Bonus Balance. When You deposit your own cash, this will be added to your account's "<strong>Cash Balance</strong>". You may withdraw any sums in your Account's Cash Balance, but You will forfeit any sums remaining on your Bonus Balance if you do so. Important notice, by creating a payout request you automatically forfeit any sums on bonus balance. Even if payout request is cancelled by our finance department due to one reason or another (e.g., fraud check etc.), bonus money will not be added back to your Bonus Balance.</p>

                <p><strong>29.3. &nbsp;&nbsp;&nbsp; Betting and making withdrawals when you have a bonus</strong></p>

                <p>The cash that you deposit will be used to place bets on the service(s). Only if there are no funds remaining in your Cash Balance will your bets be funded from your Bonus Balance automatically. Any winnings you receive when You have a bonus active on your Account will be added to your Bonus Balance and may only be withdrawn once the wagering requirements have been met.</p>

                <p>Please note where You choose to receive bonuses from us, and generate winnings from such bonuses, in excess of Five Thousand Dollars and You subsequently request a withdrawal, we reserve the right to restrict such withdrawal to be a maximum of Five Thousand Dollars in any 7 day period. The remaining amount will be placed back into your player account.</p>

                <p>All withdrawals depend on fulfilling all conditions specified above and the verification of all ID documents requested.</p>

                <p>When You have met the wagering requirements for the active bonus, the Bonus Balance associated with the active bonus (being any accrued winnings or any remaining bonus amount) will be transferred to your Cash Balance and may be withdrawn.</p>

                <p>PLEASE NOTE that if you withdraw funds from your Cash Balance before you have met the wagering requirements you will forfeit all bonuses and all accrued winnings.</p>

                <p>Please also note that if your bonus balance hits "zero" (0) at any point, then bonuses are considered redeemed and are no longer subject to Wagering requirements.</p>

                <p>If the bet you've made is lost and the current bonus balance goes down to zero, the bonus is cancelled automatically. All bets which haven't played and have been made from the balance of the cancelled bonus will be lost, including bets made on sports.</p>

                <p><strong>29.4. &nbsp;&nbsp;&nbsp; Types of bonuses</strong></p>

                <p>The terms of the specific bonus will indicate whether that bonus is withdrawable or non-redeemable. Withdrawable bonuses are those where the bonus amount together with any accrued winnings will be transferred into Your Cash Balance once the wagering requirements have been met. Non-redeemable bonuses are those where the original bonus amount will never be transferred to your Cash Balance (but any accrued winnings will be transferred once the Wagering Requirements have been met).</p>

                <p><strong>29.5. &nbsp;&nbsp;&nbsp; Wagering requirements</strong></p>

                <p>The Wagering Requirements of a bonus means the total amount of bets you must stake before the bonus and any accrued winnings are transferred into your Cash Balance and can be withdrawn. The Wagering Requirements for each bonus are set out in the specific terms for the bonus and will be expressed as a multiple of the bonus amount, or of the bonus plus the deposit amount. The Wagering Requirement for bonuses will be set out in the terms specific to that bonus. Not all bets will count towards the Wagering Requirements. Bets on Blackjack, Roulettes, Arcade games (Heads or Better, Dice Twister, etc) Video poker games (Jacks or Better, Aces and Faces, etc), Baccarat, Casino Hold'em, 2 Ways Royal, Craps and Sic Bo games contribute 5% of actual wagering on these games towards Your Wagering Requirements. Most of slot games contribute 100%. These percentages can be changed from time to time so please make sure to contact our support team each time to find out a certain game’s wagering contribution.</p>

                <p>The percentage of bets that contribute towards wagering requirements may differ for other bonus offers, but this will be made clear within the terms for those specific offers. Please read the wagering requirements carefully for each bonus offer you may receive.</p>

                <p>Partial or complete cashback of funds used to make bets on sports is not fully taken into account when the bonus is wagered. If the bet has been made on sports or another event which ends after the bonus expires, as a result the winning on this bet:</p>

                <p>a) will be credited on your cash balance, if the bonus used to make this bet has been already wagered;</p>

                <p>b) won't be credited on cash and/or bonus balance if the bonus used to make this bet has been cancelled for any reason.</p>

                <p>The 20% maximum bonus wagering rule does not apply to sports betting. It means that the maximum amount you can wager is limited only by the maximum available bet size which is indicated in the bonus wagering rules.</p>

                <p>If a bet made on sports is bigger than the player’s account balance, including any bonus which is currently being wagered, then this bet will not be taken into account.</p>

                <p><strong>29.6. &nbsp;&nbsp;&nbsp; Multiple bonuses on a single account</strong></p>

                <p>Bonuses are handled one after the other. When the earliest bonus is&nbsp;<strong>'Fulfilled'</strong>&nbsp;or&nbsp;<strong>'Revoked'</strong>&nbsp;(see the Clause below for the meaning of these terms), the next Bonus in line will become 'Active'.</p>

                <p>There are four different stages to a bonus, and these are as follows:</p>

                <p>Active – A bonus that you have started to play through, but in respect of which you have not yet completed the Wagering Requirements. While you have an active bonus on your account, you cannot withdraw any bonus amount and/or any winnings.</p>

                <p>Pending – A second or subsequent bonus on your account which you have not yet started to play through. A Pending bonus cannot be withdrawn.</p>

                <p>Fulfilled – A bonus where the wagering requirements have been met. The bonus amount and any winnings accrued will be automatically transferred into Your Cash Balance and can be withdrawn.</p>

                <p>Revoked – An Active or Pending bonus can be revoked and removed from your Bonus Balance if either: (a) You have not met the Wagering Requirements within the defined period; or, (b) you decide to withdraw any cash amount from your account before the Wagering Requirements have been met; or (c) you are in breach of this Agreement or the promotion terms and conditions.</p>

                <p>When a bonus is revoked then your Bonus Balance will be set to 0 and there will be no future liability on your part in respect of the revoked Bonus.</p>

                <p><strong>29.7. &nbsp;&nbsp;&nbsp; Promotion abuse</strong></p>

                <p>Our casino reserves the right to review transaction records and logs from time to time, for any reason whatsoever. If, upon such a review, it appears that player is participating in strategies that our casino in its sole discretion deems to be abusive, we reserve the right to revoke the entitlement of such a player to the promotion.</p>

                <p>Should the Casino become aware of any user who has accepted the bonus or a promotion with sole purpose of creating a positive expected value on bonus return by using known practices aimed at securing a cash out of said bonus, then we will enforce the below mentioned actions and will enforce immediate exclusion from this and future promotions.</p>

                <p>For sake of absolute clarity, we have individually identified named practices that we find abusive in relation to awarded bonuses:</p>

                <p>User staking bets that have no or very minimal ability of return, with sole purpose of increasing their wagering volume with minimal loss/win expectancy, will be deemed advantage play and will result in immediate forfeit of the bonus, any bonus winnings and retaining of any real monies lost to the Casino in the process. A clear-cut example of such type of play would be a simultaneous wager on both black and red in roulette, or covering of the vast majority of the table. This condition is not limited to this example alone: any wager placed with obvious intent to achieve high bet volume with minimal win expectancy will be deemed abusive toward the offer.</p>

                <p>A user who wagers high value hands (greater than $5) with the sole purpose of rapidly increasing bonus bankroll, then proceeds to drastically decrease their bet value(less than half) without having reasonably decreased their bankroll will be deemed to employing unnatural and advantageous betting patterns. Such cases may enforce below mentioned actions, but each case will be investigated and acted upon accordingly. We strongly discourage this type of play.</p>

                <p>Combinations of above: ANY user which is found employing a strategy by which they are placing high value bets while playing on any game with specific bonus weight decreased to or less than 30% and then proceed to place bets in value of less than their current average bet while changing game to higher weighted games; such user will be immediately disqualified from a bonus and will face full enforcement of below mentioned actions.</p>

                <p>Important notice, in order to protect from high-risk bonus wagering each bet should be less or equal to 20% of original amount of active bonus, if during wagering the bonus a players stakes a bet higher than 20% of original amount of active bonus then such bet will contribute only 20% of original amount of active bonus to wagering requirements.</p>

                <p>We reserve the right to revoke and/or cancel any bonuses and winnings that We regard may have been redeemed by misuse of the system. Abusing player accounts may be terminated immediately.</p>

                <p>Players found to be abusing Bonus offers may be barred from receiving further Bonuses.</p>

                <p>Please be aware that a valid telephone contact number MUST be provided on registration as We may make a confirmation call to You as part of confirming Your identity. If you cannot be contacted on the number provided any winnings accrued from use of any bonus offer may be forfeited and Your account terminated.</p>

                <p>We reserve the right to change the terms and conditions of any Bonus promotional offer at any time and it is the responsibility of the player to periodically check for changes and updates.</p>

                <p>&nbsp;</p>

                <p><strong>30. Contracting parties</strong></p>

                <p>&nbsp;</p>

                <p>It is forbidden for young.</p>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "TermsAndConditions",
    methods: {
      onPrintPage () {
        window.print()
      }
    }
  }
</script>

<style scoped>
    .print_this_page{
        position: absolute;
        right:0px;
    }
</style>
