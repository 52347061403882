<template>
  <div class="row mr-2 ml-0 justify-content-between captcha-block">
    <div
      v-lazy-container="{ selector: 'img', loading: require('@/../public/images/icons/game-images-loader.svg') }"
      class="col-10 px-0">
      <img
        ref="captcha_img"
        :data-src="captchaUrl"
        :src="captchaUrl"
        class="captcha-img"
        alt="MagnetBet casino captcha image">
    </div>
    <div class="col-2  mx-auto px-0">
      <a class="mt-1 w-100 btn  btn-sm refresh-btn" @click.prevent="updateCaptcha">
        <i class="fas fa-redo refresh-text" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ['captcha_key'],
  name: 'Captcha',
  data () {
    return {
      random: Math.random(),
    }
  },
  computed: {
    captchaUrl () {
      return `${this.$_config.captchaUrl}/${this.captcha_key}?${this.random}`
    }
  },
  methods: {
    updateCaptcha () {
      this.random = Math.random()
    }
  }
}
</script>

<style scoped>
    .refresh-btn{
        border: none !important;
        color: #fff !important;
        cursor: pointer;
        padding: 0 8px;
        height: 100%;
        display: block;
        line-height: 2;
    }

    .captcha-img {
        width: 100%;
        height: 100%;
    }
    .captcha-block,.captcha-block>div{
        height: 35px;
    }
</style>
