<template>
  <b-modal
    id="activeAliveGame"
    ref="activeAliveGameModal"
    :title="aliveSessionsCount > 1 ? 'У вас есть активные игровые сессии' : 'Активная игра - '+aliveSessions[0].game_name"
    size="md"
    :hide-footer="true"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    :hide-header-close="true"
    modal-class="main-modal page-main-block"
    header-class="text-white border-0"
    header-close-variant="orange"
    title-class="text-center underlined-muted-title"
    body-class="pt-0"
    content-class="border-0"
    centered
  >
    <div v-if="aliveSessions" class="col-12 px-0 alive-games-area">
      <div
        v-for="(aliveSession ,sessionIndex) in aliveSessions"
        :id="'alive_session_'+sessionIndex"
        :key="sessionIndex"
        class="form-group alive-game-item"
      >
        <h5 v-if="aliveSessionsCount > 1" class="text-center alive-game-name mb-3">
          <span>{{ aliveSession.game_name }}</span>
        </h5>
        <div class="row text-center" :class="aliveSessionsCount > 1 ? 'mb-1 img-narrow' : 'mb-3'">
          <img
            :src="$config.baseUrl +'/'+ aliveSession.preview" alt="MagnetBet онлайн Казино"
            class="mx-auto alive-popup-game-image"
          >
        </div>
        <div
          v-if="(response && aliveSessionInProcess === sessionIndex)"
          class="col-12 head-auth-loader p-0 row mx-0 justify-content-end"
          :class="aliveSessionsCount > 1 ? 'mb-1' : 'mb-3'"
        >
          <div class="col-12 mx-auto">
            <Loader :message="response" />
          </div>
        </div>
        <div class="row justify-content-between mt-2 mx-auto">
          <div class="col-6 text-center mx-auto">
            <a
              class="btn gray-btn white-color d-block"
              @click.prevent="closeGame(aliveSession, sessionIndex)">
              Закрыть
            </a>
          </div>
          <div class="col-6 text-center mx-auto">
            <a class="btn red-btn d-block" @click.prevent="restore(aliveSession, sessionIndex)">
              Продолжить
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="aliveSessionsCount > 1 || closingAllSessions" class="form-group mb-0 px-3 text-right">
      <span class="close-all-alive-sessions" @click="closeAllSessions">
        Закрыть все сессии
      </span>
    </div>
  </b-modal>
</template>

<script>
import Loader from '@/components/NarrowLoader'

export default {
  name: 'AliveGame',
  data () {
    return {
      response: null,
      isSubmitting: false,
      aliveSessionInProcess: 0,
      aliveSessionsCount: 0,
      closingAllSessions: false
    }
  },
  components: {
    Loader
  },
  computed: {
    aliveSessions () {
      const aliveSessions = this.$store.getters.aliveGames
      if (aliveSessions) {
        return aliveSessions
      }
      return false
    }
  },
  methods: {
    restore (game, sessionIndex) {
      this.aliveSessionInProcess = sessionIndex
      this.isSubmitting = true
      this.response = { status: 'loading' }
      if (this.aliveSessionsCount > 1) {
        this.response = { status: 'error', msg: 'Для продолжения игры закройте остальные сеансы' }
        return false
      }

      const lobbyData = game.lobbyData
      let gameNameFormatted = game.game_name.split(' ').join('-');

      this.$router.push({
        name: lobbyData ? 'LaunchLiveGame' : 'LaunchGame',
        params: {
          gameName: gameNameFormatted,
          gameId: game.game,
          game: game,
          provider: game.type,
          restoring: true,
          lobbyData: game.lobbyData
        }
      })
      this.$root.$emit('bv::hide::modal', 'activeAliveGame')
    },
    async closeAllSessions () {
      this.closingAllSessions = true
      this.response = { status: 'loading' }
      const sessions = this.aliveSessions
      if (sessions && sessions.length) {
        for (const i in sessions) {
          if (!sessions.hasOwnProperty(i)) {
            continue
          }
          const game = sessions[i]
          await this.closeGame(game, parseInt(i))
        }
      }

      this.closingAllSessions = false
      return false
    },
    closeGame (game, sessionIndex) {
      this.aliveSessionInProcess = sessionIndex
      this.isSubmitting = true
      this.response = { status: 'loading' }
      const sessionArea = document.getElementById('alive_session_' + sessionIndex)
      return new Promise(resolve => {
        this.axios.get(
          this.$_config.closeGameUrl + game.type
        ).then(({ data }) => {
          if (data.status) {
            if (data.status === 'ok') {
              this.isSubmitting = false
              this.response = { status: 'ok', msg: 'Сессия закрыта' }
              this.aliveSessionsCount = this.aliveSessionsCount - 1
              if (this.aliveSessionsCount < 1) {
                setTimeout(() => {
                  this.$root.$emit('bv::hide::modal', 'activeAliveGame')
                }, 2000)
              } else {
                setTimeout(() => {
                  sessionArea.classList.add('d-none')
                }, 1000)
              }
            } else {
              this.isSubmitting = false
              this.response = { status: 'error', msg: data.msg }
            }
          } else {
            this.isSubmitting = false
            this.response = { status: 'error', msg: 'Что-то пошло не так.Пожалуйста, сообщите об этом в контактную службу' }
            setTimeout(() => {
              window.location.reload()
            }, 3000)
          }
          this.$store.dispatch('checkUserFreeRound')
          return setTimeout(resolve, 1000)
        })
      })
    }
  },
  watch: {
    aliveSessions () {
      if (this.aliveSessions) {
        this.response = null
        this.$root.$emit('bv::show::modal', 'activeAliveGame')
      } else {
        setTimeout(() => {
          return this.$root.$emit('bv::hide::modal', 'activeAliveGame')
        }, 2000)
      }
    }
  },
  mounted () {
    // this.$store.dispatch("checkForAliveGame")
    if (this.aliveSessions.length > 1) {
      this.response = null
      this.aliveSessionsCount = this.aliveSessions.length
      this.$root.$emit('bv::show::modal', 'activeAliveGame')
    }
  }
}
</script>

<style>
    #activeAliveGame .alive-game-name{
        color: #e0dada;
        font-weight: 900;
    }
    #activeAliveGame .close-all-alive-sessions{
        color: #949292;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
    }
    .alive-game-name>span{
        border-bottom: 2px solid red;
    }
    #activeAliveGame .close-all-alive-sessions:hover{
        color: #cac8c8;
    }
    #activeAliveGame .alive-game-item{
        overflow: hidden;
    }
    #activeAliveGame .slide-height-0{
        height: 0;
        transition: 0.6s;
        margin: 0 !important;
    }
    #activeAliveGame .alive-games-area{
        max-height: 450px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    #activeAliveGame .modal-dialog{
        margin-top: 0 !important;
    }
    body.modal-open{
        max-height: 100vh !important;
        padding-right: 10px !important;
    }

</style>
<style scoped>
    .alive-popup-game-image {
        height: 150px;
        -webkit-box-shadow: 0 0 7px 2px #00000052;
        box-shadow: 0 0 7px 2px #00000052;
        padding: 15px;
        object-fit: contain;
        margin-top: 5px;
        max-width: 65%;
    }
    @media screen and (max-width: 450px) {
        .alive-popup-game-image {
            max-width: 260px;
        }
    }
</style>
