<template>
 <div class="terms" v-html="terms"></div>
</template>

<script>
export default {
  name: 'Terms',
  computed: {
    terms () {
      let terms = this.$store.getters.terms;
      if (!terms) {
        this.$store.dispatch('fetchTerms')
      }
      return this.$store.getters.terms
    }
  }
}
</script>

<style>
    .page-main-block{
        min-height: 85px;
    }
    .term-text,.term-number{
        font-size: 14px;
        color : #a5a5a5;
    }
    .term-text{
        line-height: 30px;
    }
    .term-text-item{
        margin-bottom: 7px !important;
    }
    .term-area-item{
        border-top: 1px solid #1f1f1f;
    }
    .term-title{
        text-align: center;
        margin: auto;
        font-size: 15px;
        text-transform: capitalize;
      padding: 5px 10px;
    }
    .description {
        background-color: rgba(62, 101, 129, 0.2);
        border-radius: 10px;
    }
    .description span {
        line-height: 2;
        font-size: 14px;
        color: white;
    }

    .terms-title {
        color: white;
        font-size: 20px;
    }

    .terms-logo {
        width: 60px;
        height: auto;
    }
</style>
