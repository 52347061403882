import Vue from 'vue'
import Router from 'vue-router'
import Store from '@/store'
import Home from '../components/Home'
import Live from '../components/games/Live.vue'
import Register from '@/components/user/Register'
import Terms from '@/components/Terms'
import Deposit from '@/components/payments/Deposit'
import Tickets from '@/components/main/Tickets'
import Login from '@/components/user/Login'
import Bonuses from '@/components/main/Bonuses'
import Withdraw from '@/components/payments/Withdraw'
import Account from '@/components/user/Account'
import Club from '@/components/main/Club'
import Promo from '@/components/main/Promo'
import LaunchGame from '@/components/games/LaunchGame'
import LaunchGameMobile from '@/components/games/LaunchGameMobile'
import ForgotPassword from '@/components/user/actions/ForgotPassword'
import TermsAndConditions from "@/components/main/TermsAndConditions";
import PrivacyPolicy from "@/components/main/PrivacyPolicy";
import RefundPolicy from "@/components/main/RefundPolicy";
import Promotion from "@/components/main/Promotion";
import PageNotFound from "@/components/main/PageNotFound";

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'home',
      path: '/',
      props: true,
      component: Home,
      meta: {
        title: 'Главная'
      },
      beforeEnter (to, from, next) {
        Store.dispatch('getMainSettings')
        next()
      }
    },
    {
      name: 'casino',
      path: '/casino',
      props: true,
      component: Home,
      meta: {
        title: 'Главная'
      }
    },
    {
      name: 'live',
      path: '/live',
      props: true,
      component: Live,
      meta: {
        title: 'LIVE Казино'
      }
    },
    {
      name: 'registration',
      path: '/registration',
      props: true,
      component: Register,
        meta: {
            title: 'Регистрация'
        }
    },
    {
      name: 'TermsAndConditions',
      path: '/TermsAndConditions',
      component: TermsAndConditions,
      meta: {
        title: 'Terms And Conditions'
      }
    },
    {
      name: 'PrivacyPolicy',
      path: '/PrivacyPolicy',
      component: PrivacyPolicy,
      meta: {
        title: 'Privacy Policy'
      }
    },
    {
      name: 'RefundPolicy',
      path: '/RefundPolicy',
      component: RefundPolicy,
      meta: {
        title: 'Refund Policy'
      }
    },
    {
      name: 'terms',
      path: '/terms',
      props: true,
      component: Terms,
        meta: {
            title: 'Условия'
        }
    },
    {
      name: 'deposit',
      path: '/deposit',
      props: true,
      component: Deposit,
        meta: {
            title: 'Депозит'
        }
    },
    {
      name: 'withdraw',
      path: '/payout',
      props: true,
      component: Withdraw,
        meta: {
            title: 'Выплаты'
        }
    },
    {
      name: 'login',
      path: '/signin',
      props: true,
      component: Login,
        meta: {
            title: 'Авторизация'
        }
    },
    {
      name: 'bonuses',
      path: '/bonuses',
      props: true,
      component: Bonuses,
        meta: {
            title: 'Бонусы'
        }
    },
    {
      name: 'account',
      path: '/account',
      props: true,
      component: Account,
      meta: {
        title: 'Аккаунт'
      }
    },
    {
      name: 'LaunchGame',
      path: '/Game/:gameName',
      props: true,
      component: LaunchGame,
      beforeEnter: async (to, from, next) => {
        if (Store.getters.userData === null || typeof Store.getters.userData === 'undefined') {
          return next('/signin')
        }
        return next()
      }
    },
    {
      name: 'LaunchGameMobile',
      path: '/Game/:gameName',
      props: true,
      component: LaunchGameMobile,
      beforeEnter: (to, from, next) => {
        if (Store.getters.userData === null || typeof Store.getters.userData === 'undefined') {
          return next('/signin')
        }
        return next()
      }
    },
    {
      name: 'recover',
      path: '/recover-account',
      props: true,
      component: ForgotPassword
    },
    {
      name: 'tickets',
      path: '/tickets',
      props: true,
      component: Tickets,
      meta: {
        title: 'Поддержка клиентов'
      },
      beforeEnter: (to, from, next) => {
        if (Store.getters.userData === null || typeof Store.getters.userData === 'undefined') {
          Store.dispatch('fetchUserData').then((resp) => {
            if (resp.userData) {
              return next()
            }
            return next('/signin')
          })
        }
        return next()
      }
    },
    {
      name: 'club',
      path: '/club',
      props: true,
      component: Club,
      meta: {
        title: 'VIP Клуб'
      }
    },
    {
      name: 'Promo',
      path: '/promo',
      props: true,
      component: Promo,
      meta: {
        title: 'VIP Клуб Promo'
      }
    },
    {
      name: 'Promotion',
      path: '/Promotion/:token',
      props: true,
      component: Promotion,
      meta: {
        title: 'Промо Лобби'
      },

    },
    { path: "/404", name:'PageNotFound', component: PageNotFound },
    { path: "*", redirect: "/404" },
  ]
})
