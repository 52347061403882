<template>
  <div id="header" class="header-block">
    <div class="row mx-0 navbar-container col-12">
      <div class="col-12  px-0">
        <b-navbar toggleable="xl" type="dark" variant="custom" class="main-menu py-0  px-0 h-100">
          <b-navbar-toggle id="nav-collapse-button" target="nav-collapse">
            <i v-if="collapsed" class="far fa-window-close text-white"></i>
          </b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav class="pt-0 w-100 row mx-0 justify-content-between">
            <div class="col-12 col-sm-12 col-xl-5 mt-4 px-0  menu-list">
              <b-navbar-nav
                class="justify-content-start  justify-content-md-around pt-3 pt-lg-0 pb-1 px-4 px-xl-2 row mx-0">
                <b-nav-item class="mx-0 px-xl-5  iconHomeForMobile">
                  <router-link :to="{ name: 'home' }" exact class="px-0 mt-1 d-block text-center">
                    <img :src="logo" class="mobile-expanded-logo" alt="MagnetBet logo">
                  </router-link>
                </b-nav-item>

                <b-nav-item class="mx-0 mt-3 py-lg-2 pl-5 pl-xl-0 mt-lg-0">
                  <router-link :to="{ name: 'casino' }" active-class="active-page" exact
                    class="px-0 mt-3 d-block mt-xl-0">
                    <img class="icon mx-auto" :src="icons.home" alt="MagnetBet menu icon">
                    <span class="menu-item mt- d-inline-block d-lg-inline-flex">Казино</span>
                  </router-link>
                </b-nav-item>

                <b-nav-item class="mx-0 mt-3 py-lg-2 pl-5 pl-xl-0 mt-lg-0">
                  <router-link :to="{ name: 'live' }" active-class="active-page" exact class="px-0  d-block">
                    <img class="icon text-danger mx-auto" :src="icons.liveGames" alt="MagnetBet menu icon">
                    <span class="menu-item d-inline-block d-lg-inline-flex">Live</span>
                  </router-link>
                </b-nav-item>

                <b-nav-item class="mx-0 mt-3 py-lg-2 pl-5 pl-xl-0 mt-lg-0">
                  <router-link :to="{ name: 'club' }" active-class="active-page" exact class="px-0 d-block pb-2 pb-lg-0">
                    <img class="icon mx-auto" :src="icons.vipClub" alt="MagnetBet menu icon">
                    <span class="menu-item d-inline-block d-lg-inline-flex">VIP Клуб</span>
                  </router-link>
                </b-nav-item>

                <b-nav-item class="mx-0 mt-3 py-lg-2 pl-5 pl-xl-0 mt-lg-0">
                  <router-link :to="{
                    name: 'LaunchGame',
                    params: {
                      gameName: 'WheelOfFortune',
                      gameId: 1,
                      provider: 'WheelOfFortune'
                    }
                  }" active-class="active-page" exact class="px-0 d-block pb-2 pb-lg-0">
                    <img class="icon mx-auto" :src="icons.wheel" alt="MagnetBet menu icon">
                    <span class="menu-item  d-inline-block d-lg-inline-flex">Колесо</span>
                  </router-link>
                </b-nav-item>

                <b-nav-item class="mx-0 mt-3 py-lg-2 pl-5 pl-xl-0 mt-lg-0">
                  <router-link :to="{ name: 'deposit' }" active-class="active-page" exact
                    class="px-0 d-block pb-2 pb-lg-0">
                    <img class="icon mx-auto" :src="icons.deposit" alt="MagnetBet menu icon">
                    <span class="menu-item d-inline-block d-lg-inline-flex">Депозит</span>
                  </router-link>
                </b-nav-item>

                <b-nav-item class="mx-0 mt-3 py-lg-2 pl-5 pl-xl-0 mt-lg-0">
                  <router-link :to="{ name: 'withdraw' }" active-class="active-page" exact
                    class="px-0 d-block pb-2 pb-lg-0">
                    <img class="icon mx-auto" :src="icons.withdraw" alt="MagnetBet menu icon">
                    <span class="menu-item d-inline-block d-lg-inline-flex">Выплата</span>
                  </router-link>
                </b-nav-item>

                <b-nav-item class="mx-0 mt-3 py-lg-2 pl-5 pl-xl-0 mt-lg-0">
                  <router-link :to="{ name: 'bonuses' }" active-class="active-page" exact
                    class="px-0 d-block pb-2 pb-lg-0">
                    <img class="icon mx-auto" :src="icons.bonus" alt="MagnetBet menu icon">
                    <span class="menu-item d-inline-block d-lg-inline-flex">Бонусы</span>
                  </router-link>
                </b-nav-item>

                <b-nav-item class="mx-0 mt-3 mt-lg-0 py-lg-3  ">
                  <div class="col-lg-12 mt-4 menu-list  ">
                    <b-navbar-nav class="justify-content-start pt-3 mt-4 row mx-0 pl-3 nested-nav">
                      <div
                        class="col-12 col-md-4 col-xl-2 footer-contact-item row mx-0 justify-content-center pl-0 mb-2 mb-lg-0 d-xl-block">
                        <div v-for="(contact, index) in contacts" :key="index"
                          class="col-2 footer-contact-icon-area p-0 ">
                          <a :href="contact.link" target="_blank" rel="noreferrer"
                            class="w-100 d-flex h-100 footer-contact-text text-white text-center"
                            v-lazy-container="{ selector: 'img', loading: loader }"
                            @click="gotoExternalLink(contact.link)">
                            <img :src="contact.icon" :alt="'MagnetBet Казино : контакт ' + contact.title"
                              :data-src="contact.icon" class="mw-100 pl-2 mx-auto">
                          </a>
                        </div>
                      </div>

                    </b-navbar-nav>
                  </div>
                </b-nav-item>
              </b-navbar-nav>
            </div>

            <div class="col-2  d-lg-flex px-0 ">
              <b-navbar-brand class="mx-auto header-logo-div px-0 mb-xl-4  mw-100">
                <router-link :to="{ name: 'home' }" class="d-inline-block  middle-logo-container mw-100">
                  <img class="header-logo mw-100" :src="logo" alt="MagnetBet онлайн казино logo">
                </router-link>
              </b-navbar-brand>
            </div>


            <div v-if="userDataFetched"
              class="col-12 col-lg-5 userInfoo d-lg-flex px-lg-0 pt-lg-1 row mx-auto justify-content-between">

              <b-navbar-nav v-if="!userData" class="d-block mb-1 row mx-0 w-100">

                <HeaderAuth class=" "></HeaderAuth>
              </b-navbar-nav>
              <b-navbar-nav v-else class="row mx-0 justify-content-end px-0 mt-3 d-flex col-12">

                <div class="col-7 col-xl-5 text-right mt-1 px-0 user-info-area user-info">
                  <router-link class="pt-0 header-username menu-item" :to="{ path: '/account' }" title="Войти в профиль">
                    <!-- <img src="../../../public/images/icons/levels/user.png" class="mr-2  header_user_icon" alt="MagnetBet user icon"> -->
                    <span class="text-white mr-1">{{ userData.login }}</span>
                    <span class="my-auto header-balance-info ml-xl-3 d-inline-block">
                      <span class="text-muted">Баланс: </span>
                      <span class="text-light-green">
                        <span class="blnc">{{ userBalance ? userBalance : userData.balance }}</span> <small>Руб</small>
                      </span>
                    </span>
                  </router-link>
                </div>
                <div class="col-4 col-xl-3 px-0 text-center row mx-0">
                  <button name="logout" class="btn text-white d-none d-xl-block logout-btn" @click.prevent="logout">
                    Выйти |
                    <i v-if="!loggingOut" class="fa fa-arrow-right"></i>
                    <i v-else class="fa fa-spinner fa-spin"></i>
                  </button>
                </div>
              </b-navbar-nav>

            </div>
            <div v-else class="col-12 col-lg-5 d-none d-lg-block px-lg-0
                        row justify-content-center w-100 color-red mx-0 h-100 py-4 text-center pr-0">
              <i class="fa fa-spinner fa-spin fa-fw my-auto"></i>
            </div>
          </b-collapse>

          <div class="row w-100 d-lg-1 mx-0 mobile-header-menu col-12 px-0 h-100">
            <div class="col-8 col-md-6 px-0 text-center ml-auto mr-0">
              <b-navbar-brand class="mx-0 px-0">
                <router-link :to="{ name: 'home' }" class="mobile-logo-link ">
                  <img class="px-3 header-logo d" :src="logo" alt="MagnetBet logo">
                </router-link>
              </b-navbar-brand>
            </div>
            <div class="col-2 col-md-3 px-0 account-mobile text-right h-100 row mx-0 justify-content-end">
              <div v-if="!userData" class="text-right pl-4 pr-0 px-sm-3 px-md-3 my-auto">
                <router-link :to="{ name: 'login' }" exact>
                  <img src="../../../public/images/icons/user.svg" class="login-icon d-xl-none" alt="MagnetBet user icon">
                </router-link>
              </div>
              <div v-else class="text-right mobile-account-droper px-0 px-sm-3 px-md-3 my-auto mr-md-0 ml-md-auto">
                <b-dropdown class="mobile-account-drop">
                  <template v-slot:button-content class="account-drop-btn">
                    <img src="../../../public/images/icons/user.svg" class="login-icon" alt="MagnetBet signin icon">
                  </template>
                  <b-dropdown-item>
                    <router-link :to="{ path: '/account' }">
                      Профиль
                    </router-link>
                    <br>
                    <span class="balance-amount">
                      ({{ userData.balance }} Руб)
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <a href="#" @click.prevent="logout"> Выход |
                      <i v-if="!loggingOut" class="fa fa-arrow-right"></i>
                      <i v-else class="fa fa-spinner fa-spin"></i>
                    </a>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </b-navbar>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderAuth from './HeaderAuth'

export default {
  name: 'Header',
  components: {
    HeaderAuth
  },
  data() {
    return {
      contacts: {
        tgChannel: {
          title: 'Telegram Канал',
          link: 'https://t.me/magnetcasino',
          info_text: '@magnetcasino',
          icon: require('@/../public/images/icons/social/telegram_.png')
        },
        tg: {
          title: 'Telegram Менеджер',
          link: 'https://t.me/zevs777manager',
          info_text: '@magnetcasinomanager',
          icon: require('@/../public/images/icons/social/telegram_manager.png')
        },
        tgBot: {
          title: 'Telegram Бот',
          link: 'https://t.me/MagnetBet_bot',
          info_text: '@MagnetBet_bot',
          icon: require('@/../public/images/icons/social/telegram_bot.png')
        },
        email: {
          title: 'Email',
          link: 'mailto:magnetbet@yandex.ru',
          info_text: 'magnetbet@yandex.ru',
          icon: require('@/../public/images/icons/social/email.png')
        },
        vk: {
          title: 'VK',
          link: 'https://vk.com/magnetmanager',
          info_text: 'Менеджер',
          icon: require('@/../public/images/icons/social/vk.png')
        },
        finsupport: {
          title: 'Финансовая поддержка',
          link: 'https://t.me/vpayqiwi2',
          info_text: 'Фин.поддержка',
          icon: require('@/../public/images/icons/social/finsupport.png')
        }
      },
      loader: require('@/../public/images/icons/game-images-loader.svg'),
      headerLogo: true,
      collapsed: true,
      logo: require('@/../public/images/logo/logo.png'),
      loggingOut: false,
      icons: {
        wheel: require('@/../public/images/icons/menu/wheel.svg'),
        liveGames: require('@/../public/images/icons/menu/live.svg'),
        bonus: require('@/../public/images/icons/menu/bonus.svg'),
        deposit: require('@/../public/images/icons/menu/deposit.svg'),
        withdraw: require('@/../public/images/icons/menu/withdraw.svg'),
        terms: require('@/../public/images/icons/menu/terms.png'),
        home: require('@/../public/images/icons/menu/casino.svg'),
        vipClub: require('@/../public/images/icons/menu/vip-club.svg'),
        statistics: require('@/../public/images/icons/menu/statistics.png'),
        support: require('@/../public/images/icons/menu/support.png')
      },
      headerTelegram: false,
      userBalance: null
    }
  },
  computed: {
    userData() {
      return this.$store.getters.userData
    },
    userDataFetched() {
      return this.$store.getters.userDataFetched
    },
    siteSettings() {
      return this.$store.getters.getMainSettings
    },
    balance() {
      let balance = this.$store.getters.getBalance
      return balance.toFixed(2)
    },
    isMobile() {
      return this.$_config.environment.mobile
    }
  },
  methods: {
    gotoExternalLink(url) {
      window.open(url, '_blank');
    },
    logout() {
      this.loggingOut = true
      this.axios.get(
        this.$_config.baseUrl + '/Api/logout'
      ).then(() => {
        this.$store.dispatch('removeUserData')
        window.location.reload(true)
      }).catch(err => {
        console.log(err)
      })
    },
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId === 'nav-collapse') {
        isJustShown ? this.collapsed = true : this.collapsed = false
      }
    });
    this.userBalance = this.balance
  },
  watch: {
    collapsed(newVal) {
      const chatBlock = document.getElementById('tawkchat-container')
      if (newVal === true) {
        if (chatBlock) {
          let chatStyles = chatBlock.style.cssText
          chatBlock.style.cssText = chatStyles + '; z-index:10 !important ;'
        }
        document.getElementById("app").classList.add('app-hide-overflow')
      } else {
        document.getElementById("app").classList.remove('app-hide-overflow')
      }
    },
    balance: {
      handler: function (newValue) {
        if (newValue) {
          this.userBalance = newValue
        }
      }
    }
  }
}
</script>

<style>

.login-icon{
  width: 20px;
}


#nav-collapse-button {
  z-index: 21;
}

.theme-background-color {
  background-color: #000 !important;
}

.app-hide-overflow {
  max-height: 100vh;
  overflow: hidden;
}

.custom-tawk {
  position: absolute;
  width: 100px;
  height: 100px;
  right: 10px;
  border: aliceblue;
  bottom: -30px;
  overflow: hidden;
  display: block;
}

.mobile-expanded-logo {
  width: 110px;
}

.logout-btn .fa-spinner {
  color: #ffffff;
  font-size: 14px;
}

.header-balance-info {
  font-size: 14px;
}

.header-username {
  color: #ffffff;
}

.dropdown-item a {
  color: #0b0b18;
  text-decoration: none;
}

.dropdown-item:hover {
  background-color: #0b0b18 !important;
}

.dropdown-item:hover a {
  color: white !important;
}

.active-page span {
  color: #950101 !important;
}

.account-icon .dropdown-toggle {
  background-color: transparent !important;
  border: none !important;
  padding-top: 0 !important;
}

.main-menu #nav-collapse {
  width: 100%;
  z-index: 20;
  top: 0;
  left: 0;
}

.menu-list ul .nav-item {
  text-align: center;
}

.menu-list ul .nav-item .nav-link {
  padding: 0;
}

.account-icon ul {
  left: inherit;
  right: 0 !important;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28134, 0, 25, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.menu-item {
  margin-left: 6px;
}
.menu-item:hover{
  color: #950101;
}

@media (min-width: 320px) and (max-width: 1199px) {

  .nested-nav {
    padding-bottom: 60px;
  }

  .support-item {
    font-size: 18px;
  }

  .user-info-area {
    display: none;
  }

  .header-logo-div {
    display: none !important;
  }


  .mobile-account-drop button {
    background-color: transparent !important;
    border: none;
  }

  .mobile-account-drop button:after {
    display: none;
  }

  .mobile-account-drop button:hover {
    background-color: transparent !important;
    border: none;
    box-shadow: 0 0 0 0.1rem rgba(130, 138, 145, 0.5)
  }

  .mobile-account-drop .dropdown-menu {
    right: 0 !important;
    top: unset;
    background-color: #0b1112;
    color: #ffffff;
    box-shadow: 0 0 0 0.1rem inset rgba(130, 138, 145, 0.5);
    padding: 2px 0;
    transform: translate3d(-67px, 48px, 0px) !important;
    min-width: 8rem;
  }

  .mobile-account-drop .dropdown-item,
  .mobile-account-drop .dropdown-item a {
    color: #757a7d;
    font-size: 15px;
  }


  .menu-list ul .nav-item {
    font-size: 18px;
    text-align: left;
  }

  .dropdown-menu li:first-child {
    box-shadow: 0 -1px 0 1px inset rgba(130, 138, 145, 0.5);
  }

  .dropdown-menu li {
    text-align: center;
    line-height: 2;
  }

  .mobile-header-menu .header-logo {
    position: unset;
    width: 150px;
    margin-top: 10px;
    background: none;
  }

  .account-icon .dropdown-toggle {
    padding-left: 0;
  }

  .mobile-logo-link {
    display: inline-block;
  }

  .menu-list {
    background-color: black;
    margin-right: 0;
    margin-left: auto;
    height: 100%;
  }

  .main-menu .navbar-collapse .navbar-toggler {
    z-index: 220;
  }

  .main-menu .navbar-collapse {
    position: fixed;
    width: 100vw;
    z-index: 10;
    top: 0;
    height: 100vh;
    padding-top: 20px;
    overflow-y: scroll;
    background-color: #0000006b;

  }

  .main-menu .navbar-collapse::-webkit-scrollbar {
    display: none;
  }

  .main-menu .navbar-collapse {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .collapse.show .navbar-nav {
    height: auto !important;
    background-color: #000000;
  }

  .main-menu .navbar-toggler[aria-expanded="true"] {
    z-index: 21;
    position: fixed;
    top: 10px;
    left: 10px;
    width: 45px;
    background: #980019;
    height: 37px;
    font-size: 20px;
    border: none;
    border-radius: 3px;
  }

  .main-menu .navbar-toggler[aria-expanded="false"] {
    top: 20px;
    font-size: unset;
    border: none !important;
    box-shadow: 0 0 3px 1px #b50b2b;
    z-index: 50;
    margin-top: 0;
    border-radius: 3px;
    right: 0;
    margin-left: 0;
  }

  .active-page span {
    width: auto;
    color: #950101 !important;
    font-weight: 800;
  }

  .router-link-exact-active * {
    color: #950101 !important;
    font-weight: 800;
  }

  .nav-link .icon {
    height: 35px !important;
    object-fit: contain;
  }

  .logout-btn {
    display: none;
  }

  .header-logo {
    display: block;
  }
}



@media (min-width: 1200px) {

  .mobile-logo-link,
  .mobile-account-droper,
  .iconHomeForMobile,
  .nested-nav,
  .andriodVercionIcon,
  .staticsIcon {
    display: none;
  }

  .header-logo {
    display: block;
  }
}

@media (min-width: 520px) and (max-width: 991px) {
  .nav-link .icon {
    display: inline-block !important;
    object-fit: contain;

  }

  .menu-item {
    display: inline-block !important;
    padding-left: 15px !important;
    width: auto;
    font-size: 14px;
  }

  .menu-list ul .nav-item:first-child {
    width: 100%;
  }

  .header-logo {
    display: block;
  }
}

@media (min-width:1200px) {
  .userInfoo {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 1530px) {
  .header-logo {
    padding-bottom: 18px;
  }
}

@media screen and (max-width: 375px) {
  .main-menu .navbar-toggler[aria-expanded="true"] {
    font-size: 19px;
    right: unset;
    left: unset;
  }
}
</style>
<style scoped>
.balance-amount {
  color: #1ba226;
  font-size: 14px;
}

.header_user_icon {
  height: 33px;
}

.icon {
  padding-bottom: 2px;
  width: 23px;
  height: 25px;
  display: inline-flex;
  object-fit: contain;
}

.navbar-container {
  background-color: #0a090f;
  height: 80px;
  position: fixed;
  z-index: 20;
  border-bottom: 1px solid #1b1c21;
  box-shadow: 0 0 7px 1px #8a8b8d;
}

.mobile-header-menu {
  position: absolute;
  top: 0;
  left: 0;
}

.search-mobile {
  right: 100px;
}

.account-mobile {
  cursor: pointer;
}

.logout-btn {
  font-size: 14px;
  background-color: #81031a;
  padding: 1px 20px;
  height: 30px;
  margin: auto;
  border-radius: 3px
}

.fa-search {
  font-size: 20px;
}

@media (min-width: 320px) and (max-width: 1530px) {
  .middle-logo-container img {
    top: 9px;
  }

  .menu-list {
    font-size: 12px;
    min-height: 100vh;
    width: 100%;
    height: auto;
  }
}



@media (min-width: 1199px) and (max-width: 1600px) {
  .icon {
    display: none;
  }
}


@media (min-width: 768px) and (max-width: 1199px) {
  .menu-list {
    min-height: unset;
  }


}

@media (max-width: 1199px) {
  .icon {
    width: 35px;
    display: inline-block;
    object-fit: contain;
    height: 35px;
  }

  .menu-item {
    display: inline-block !important;
    padding-left: 15px !important;
    width: auto;
    font-size: 14px;
  }

  .nested-nav {
    border-top: 1px solid #950101;
    width: 100%;
  }
}

.menu-list {
  height: 100%;
  font-size: 11px;
  min-height: unset;
}

.text-animation {
  animation: color-change 1s infinite;
}

@keyframes color-change {
  0% {
    color: #8e031a;
  }

  50% {
    color: #e52e06;
  }

  100% {
    color: #8e031a;
  }
}
</style>