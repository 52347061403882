<template>
  <div class="py-3 py-lg-5 col-12">
    <div style="display: none">{{ busyLobbies }}</div>
    <div class="row mx-0 mt-5">
      <div class="row mx-0 slider-container h-100 w-100" v-if="sm_images">
        <div class="col-12 px-0 mt-3 mt-md-5" v-if="machines">
          <div class="row my-3 mx-0 w-100 justify-content-center slide1 active" id="slide_1">
            <div class="col-3 col-xl-2 px-0 text-center pt-0 float-left"
              @click="openModalLobby(machine.state, machine.id)" v-for="(machine, index) in machines"
              :key="index">
              <img :src="getMachineImage(machine.state, machine.name)" alt="Magnet.Bet - Slot Machine" :data-id="machine.id"
                :data-type="1" class="mw-100 slot-machine-img" :id="'machine_' + machine.id"
                :class="machine.state ? 'free_machine' : 'busy_machine'">
            </div>
          </div>
        </div>
        <div class="alert alert-primary" v-else>
          <div class="col-12 resp-loader loading">
            <i class="fa fa-spinner fa-spin fa-fw" />
          </div>
        </div>

      </div>
    </div>

    <b-modal id="clubModal" ref="clubModal" size="md" modal-class="main-modal page-main-block" ok-title="Закрыть" ok-only
      ok-variant="success modal-ok" header-class="text-white border-0" header-close-variant="orange"
      footer-class="border-0" hide-footer body-class="pt-0" centered>
      <div class="row mx-0 col-12 mx-auto px-0 px-md-3">
        <div class="col-12 mx-auto pt-3 pb-2 px-0">
          <span>
            Пополнение счета аппарата
            <br><br>

            <b-row class="mx-0">
              <input id="withdraw-amount" v-model="payload.amount" class="form-control main-input deposit-input mb-4"
                type="text" :min="100" placeholder="Введите сумму" required>
            </b-row>

            <b-row>
              <div class="row mx-0 w-100 justify-content-center justify-content-lg-start">
                <button class="btn btn-red-to-orange deposit-amount-btn col-3 col-xl-2 mb-3 mb-xl-1 mx-auto"
                  v-on:click.prevent="setDepositAmount(amount)"
                  v-for="(amount, idx) in defaultDepositAmounts" :key="idx">
                  {{ amount }} руб
                </button>
              </div>
            </b-row>

            <div class="row w-100 mx-0">
              <Alert :message="formResponse"></Alert>
            </div>
            <button name="openConfirmModal" class="btn red-btn w-100 text-white mt-4" :disabled="isSubmitting"
              @click.prevent="createLobby()">
              Перевести на аппарат
            </button>
          </span>

        </div>
      </div>


    </b-modal>

    <b-modal id="clubModalBusy" ref="clubModalBusy" size="md" modal-class="main-modal page-main-block" ok-title="Закрыть"
      ok-only ok-variant="success modal-ok" header-class="text-white border-0" header-close-variant="orange"
      footer-class="border-0" hide-footer body-class="pt-0" centered>
      <div class="row mx-0 col-12 mx-auto px-0 px-md-3">
        <div class="col-12 mx-auto pt-3 pb-2 px-0">
          <span>
            Аппарат занят! Каждый 3 минуты обновляется занятость аппарата
          </span>
        </div>
      </div>
    </b-modal>

    <b-modal id="clubModalRestore" ref="clubModalRestore" size="md" modal-class="main-modal page-main-block"
      ok-title="Закрыть" ok-only ok-variant="success modal-ok" header-class="text-white border-0"
      header-close-variant="orange" footer-class="border-0" hide-footer body-class="pt-0" centered>
      <div class="row mx-0 col-12 mx-auto px-0 px-md-3">
        <div class="col-12 text-center mb-4">
          <span class="denomination-hint">
            У Вас Активный Аппарат
            <br>
            <small> Хотите продолжить?</small>
          </span>
        </div>

        <div class="col-5 col-lg-5 px-0 mx-2 text-right mx-auto text-center">
          <a class="btn gray-btn btn-block" @click="closeLobby()">Закрыть</a>
        </div>
        <div class="col-5 col-lg-5 px-0 mx-0 mx-lg-2 mx-auto mb-3">
          <a class="btn red-btn w-100 border-0" @click="restoreLobby()"> Продолжить</a>
        </div>
      </div>
    </b-modal>
    <iframe id="lobbyLounchFrame" ref="lobbyLounchFrame" class="lobby-area" v-show="isShowing" :src="iframeSrc"
      allowfullscreen style="width:100%; height: 800px; min-height: 100vh;"></iframe>
  </div>
</template>

<script>
import Alert from '@/components/Alert'
import { scroller } from 'vue-scrollto/src/scrollTo'

const secondScrollTo = scroller()

export default {
  data() {
    return {
      loader: require('@/../public/images/icons/small-loader.svg'),
      lobby_bonus: require('@/../public/images/slot_machine/lobby_bonuses.png'),
      payload: {
        amount: ''
      },
      isSubmitting: false,
      formResponse: {},
      selctedClubId: false,
      isShowing: false,
      iframeSrc: null,
      defaultDepositAmounts: [
        50, 100, 150, 200
      ],
      selectedMachineState: null,
      gettingData: null,
      lobbyLounchFrame: null,
    }
  },
  components: {
    Alert
  },
  computed: {
    sm_images() {
      let folder = 'machines'
      if (window.innerWidth < 1200) {
        folder = 'machines_mobile'
      }

      let sm = []
      for (let i = 1; i < 13; i++) {
          sm[i] = {
            free: require('@/../public/images/slot_machine/' + folder + '/free/free_' + i + '.png'),
            busy: require('@/../public/images/slot_machine/' + folder + '/busy/busy_' + i + '.png')
          }
      }

      return sm
    },
    busyLobbies: {
      get() {
        let result = this.$store.getters.getBusyLobbies
        if (result) {
          let free_machines = document.getElementsByClassName('slot-machine-img')
          Array.prototype.forEach.call(free_machines, (slot_machine) => {
            let curr_id = slot_machine.getAttribute('data-id')
            if (result[curr_id]) {
              document.getElementById('machine_' + curr_id).src = this.sm_images[curr_id].busy
            } else {
              document.getElementById('machine_' + curr_id).src = this.sm_images[curr_id].free
            }
          });
        }
        return result
      },
      set(newVal) {
        return newVal
      }
    },
    machines() {
      return this.$store.getters.getSlotMachineState
    },
    userData() {
      return this.$store.getters.userData
    },
    checkLobbyAliveGame() {
      return this.$store.getters.getLobbyAliveGame
    }
  },
  methods: {
    async closeLobby() {
      if (this.selectedMachineState && this.selectedMachineState.id) {
        await this.$store.dispatch('closeSlotMachine', this.selectedMachineState.id)
        this.$root.$emit('bv::hide::modal', 'clubModalRestore')

      } else if (this.checkLobbyAliveGame && this.checkLobbyAliveGame.id) {
        await this.$store.dispatch('closeSlotMachine', this.checkLobbyAliveGame.id)
        this.$root.$emit('bv::hide::modal', 'clubModalRestore')
      } else {
        return
      }

      window.location.reload()
    },
    restoreLobby() {
      if (this.selectedMachineState && this.selectedMachineState.mode == 'restore' && this.selectedMachineState.aliveUrl != '') {
        this.isShowing = true
        this.iframeSrc = this.selectedMachineState.aliveUrl
        this.lobbyLounchFrame.style.display = 'block'
        setTimeout(() => {
          secondScrollTo('#lobbyLounchFrame')
        }, 600)
        this.$root.$emit('bv::hide::modal', 'clubModalRestore')
      }

      if (this.checkLobbyAliveGame && this.checkLobbyAliveGame.mode == 'restore' && this.checkLobbyAliveGame.aliveUrl != '') {
        this.lobbyLounchFrame.style.display = 'block'
        this.isShowing = true
        this.iframeSrc = this.checkLobbyAliveGame.aliveUrl
        setTimeout(() => {
          secondScrollTo('#lobbyLounchFrame')
        }, 600)
        this.$root.$emit('bv::hide::modal', 'clubModalRestore')
      }
    },
    getMachineImage(state, number) {
      state = state == 0 ? 'busy' : 'free'
      return this.sm_images[number][state]

    },
    setDepositAmount(amount) {
      this.payload.amount = amount
      document.getElementById('withdraw-amount').value = amount
    },
    openModalLobby(state, id) {
      if (state) {
        this.openModal(id)
      } else {
        this.openModalBusy(id, state)
      }
    },
    openModal(id) {
      if (!this.userData) {
        this.$root.$emit('bv::show::modal', 'loginModal')
        return this.$root.$emit('reasonToLogin', 'Нужно авторизоваться')
      }
      this.selctedClubId = id;
      this.$root.$emit('bv::show::modal', 'clubModal')
    },
    async openModalBusy(machine_id, state) {
      if (state == 0) {
        return
      }

      if (!this.userData) {
        this.$root.$emit('bv::show::modal', 'loginModal')
        return this.$root.$emit('reasonToLogin', 'Нужно авторизоваться')
      }

      // check is user in this machine
      await this.$store.dispatch('checkSlotMachineState', machine_id)
      this.selectedMachineState = await this.$store.getters.getUsersLobbyStatus

      if (this.selectedMachineState) {
        this.$root.$emit('bv::show::modal', 'clubModalRestore')
      }
    },
    async createLobby() {
      if (!this.userData) {
        this.$root.$emit('bv::show::modal', 'loginModal')
        return this.$root.$emit('reasonToLogin', 'Для вывода средств нужно авторизоваться')
      }

      this.formResponse = { status: 'loading' }
      this.isSubmitting = true
      this.axios.post(
        this.$_config.baseUrl + '/Api/createLobby',
        {
          amount: this.payload.amount,
          id: this.selctedClubId
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(({ data }) => {
        if (data.status === 'ok') {
          this.$root.$emit('bv::hide::modal', 'clubModal')
          this.formResponse = {}
          this.payload.amount = null

          this.lobbyLounchFrame.style.display = 'block'
          this.isShowing = true
          this.iframeSrc = data.data.lobbyUrlRes
        } else if (data.status === 'error') {
          if (data.data.mode === 'restore') {
            this.selectedMachineState = {}

            this.selectedMachineState.mode = 'restore'
            this.selectedMachineState.aliveUrl = data.data.aliveUrl
            this.selectedMachineState.id = data.data.id

            this.$root.$emit('bv::hide::modal', 'clubModal')
            this.$root.$emit('bv::show::modal', 'clubModalRestore')
          }

          this.formResponse = data
        } else {
          this.formResponse = data
        }
        this.isSubmitting = false

        setTimeout(() => {
          secondScrollTo('#lobbyLounchFrame')
        }, 600)

      }).catch(err => {
        console.log(err)
      })
      return true
    },
  },
  mounted() {
    this.$store.dispatch('fetchSlotMachineStates')
    this.$store.dispatch('checkLobbyAliveGame')
    this.lobbyLounchFrame = document.getElementById('lobbyLounchFrame')
    window.scrollTo({
      top: 0
    })

    const lobbyLounchFrame = document.getElementById('lobbyLounchFrame');
    window.addEventListener('message', function (e) {
      if (e.data === "close-lobby-iframe" || e.message === "close-lobby-iframe") {
        lobbyLounchFrame.style.display = 'none';
      }
    })
  },
  beforeDestroy() {
    clearInterval(this.gettingData)
  },
  watch: {
    checkLobbyAliveGame(newValue) {
      if (newValue) {
        this.$root.$emit('bv::show::modal', 'clubModalRestore')

      }
    }
  }
}
</script>

<style>
.lobby-page-tab .nav-item {
  width: 100% !important;
}

#slot-machine .carousel-indicators {
  bottom: -60px !important;
}

#slot-machine .carousel-indicators li:first-child::after {
  content: "мин: 50руб" !important;
}

#slot-machine .carousel-indicators li::after {
  content: "мин: 100руб" !important;
}

#slot-machine .carousel-indicators li:last-child::after {
  content: "мин: 250руб" !important;
}

#slot-machine .carousel-indicators li {
  min-width: 250px !important;
  border-radius: 3px;
  height: 40px !important;
  text-indent: unset !important;
  text-align: center;
  line-height: 2.5;
  color: white !important;
  background-color: #53111d !important;
}


@media screen and (max-width: 768px) {
  #slot-machine .carousel-indicators li {
    min-width: 120px !important;
  }
}
</style>
<style scoped>
.slot-machine-balance {
  position: absolute;
  top: 50%;
  left: calc(50% - 60px);
  background-color: black;
  padding: 0 5px;
  border-radius: 7px;
  font-size: 14px;
}

.lobby-area {
  margin-top: 70px;
  border: none;
}

.deposit-input {
  border-bottom: 1px solid #950101;
  padding-left: 10px;
}

.slot-machine-img {
  cursor: pointer;
}

@-webkit-keyframes sliding {
  from {
    -webkit-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }

  to {
    -webkit-transform: translateY(-600px);
    -o-transform: translateY(-600px);
    transform: translateY(-600px);
  }
}

@keyframes sliding {
  from {
    -ms-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }

  to {
    -ms-transform: translateY(-600px);
    -moz-transform: translateY(-600px);
    -webkit-transform: translateY(-600px);
    -o-transform: translateY(-600px);
    transform: translateY(-600px);
  }
}

@media screen and (max-width: 768px) {
  .slot-machine-balance {
    left: calc(50% - 10px);
    font-size: 10px;
  }
}
</style>
