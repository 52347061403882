<template>
    <div id="online-chat">
        <b-dropdown id="dropdown-dropup" class="dropup" v-if="needToOpen && isOpening">
            <template #button-content v-if="!showCloseIcon">
                <img v-on:click="changeIcon()" :src="chat" />
            </template>
            <template #button-content v-else>
                <img v-on:click="changeIcon()" :src="close" />
            </template>

            <b-dropdown-item v-on:click="callClickFunction()">
                <strong> Онлайн чат </strong>
            </b-dropdown-item>

            <div v-for="(contact, index) in contacts" :key="index">
                <b-dropdown-item v-on:click="showChatIcon()" :href="contact.link" target="_blank">
                    <strong> {{ contact.title }} </strong>
                </b-dropdown-item>
            </div>
        </b-dropdown>
        <button v-else v-on:click="buttonForClosing()" class="buttonForClosing">
            <img :src="close" />
        </button>
    </div>
</template>
  
<script>
export default {
    name: 'ChatForConsulting',
    data() {
        return {
            needToOpen: true,
            showCloseIcon: false,
            chat: require("@/../public/images/icons/chat/chat.svg"),
            close: require("@/../public/images/icons/chat/close.svg"),
            contacts: {
                finsupport: {
                    title: 'Тех поддержка',
                    link: 'https://t.me/vpayqiwi2',
                    info_text: 'Тех.поддержка',
                },
                tg: {
                    title: 'Telegram Manager',
                    link: 'https://t.me/zevs777manager',
                    info_text: '@zevs777manager',
                },
                vk: {
                    title: 'VK Manager',
                    link: 'https://vk.com/magnetmanager',
                    info_text: 'Менеджер',
                },
                email: {
                    title: 'Email',
                    link: 'mailto:Zevs777casino@mail.ru',
                    info_text: 'Zevs777casino@mail.ru',
                }
            },
            isOpening: true
        }
    },
    methods: {
        changeIcon() {
            let classList = document.querySelector('#dropdown-dropup').getAttribute('class').split(' ')
            this.showCloseIcon = !classList.includes('show')
        },
        buttonForClosing() {
            this.needToOpen = true
            this.showCloseIcon = false
            let iframForButton = document.querySelector('.widget-visible iframe')

            if (iframForButton) {
                let buttonFromIfram = iframForButton.contentDocument.querySelector('.font-lato button')
                if (buttonFromIfram) {
                    buttonFromIfram.click()
                }
            }
        },
        callClickFunction() {
            window.Tawk_API.toggle()

            setTimeout(() => {
                let iframe = document.querySelector('.widget-visible iframe')
                if (iframe) {
                    iframe.setAttribute('style', "display:none !important")
                }
            }, '50')

            this.needToOpen = false
            this.showCloseIcon = true

            let iframForButton = document.querySelector('.widget-hidden iframe')
            if (iframForButton) {
                if (iframForButton.contentDocument.querySelector('html').getAttribute('class') == 'tawk-mobile') {
                    this.needToOpen = true
                    this.showCloseIcon = false
                }
            }
        },
        showChatIcon() {
            this.showCloseIcon = false
        }
    }
}
</script>

<style>
#online-chat #dropdown-dropup {
    position: fixed;
    right: 25px;
    bottom: 20px;
    border: none !important;
    z-index: 21;
}

#online-chat #dropdown-dropup .dropdown-toggle {
    height: 60px;
    width: 60px;
    background-color: #81031a;
    border-radius: 50%;
    box-shadow: none !important;
}

#online-chat .dropup .dropdown-toggle::after {
    display: none !important;
}

.buttonForClosing {
    position: fixed;
    right: 25px;
    bottom: 20px;
    border: none;
    width: 60px;
    height: 60px;
    background-color: #81031a;
    border-radius: 50%;
}

#online-chat #dropdown-dropup .dropdown-item {
    background-color: #81031a !important;
    border-bottom: 1px solid white;
    justify-content: center;
    display: flex;
    gap: 10px;
    color: white;
    margin: 10px;
    width: 220px;
    height: 45px;
    min-height: 30px;
    align-items: center;
    border-radius: 10px;
}

#online-chat #dropdown-dropup li {
    box-shadow: none !important;
}

.btn-secondary {
    border-color: #81031a !important;
}
</style>