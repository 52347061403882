<template>
  <div>
    <b-modal
        id="PromotionLoginModal"
        ref="PromotionLoginModal"
        size="md"
        modal-class="main-modal page-main-block"
        ok-title="Закрыть"
        ok-only
        ok-variant="success modal-ok"
        hide-footer
        hide-header
        body-class="pt-0"
        centered>
      <div class="row mx-0 col-12 mx-auto px-0 px-md-3">
        <div class="col-12 mx-auto pt-3 pb-2 px-0">
          <div style="display: none">{{ token }}</div>

          <b-row class="w-100 justify-content-center text-center">
            <h6 class="w-100">Для продолжения игры или получения выигрыша пожалуйста пройдите регистрацию.</h6>
            <h6 class="w-100 text-dark-orange" v-if="balance"><strong>Ваш выигрыш {{ balance }} руб</strong></h6>
          </b-row>

          <b-form
              class="login-form popup-login-form py-t pb-0"
              @submit.prevent="UpdatePromotionUser">
            <div class="col-12  mx-auto mb-1">
              <div class="col-12 px-0">
                <div class="input-group mb-4">
                  <div class="col-12 px-0">
                    <input
                        v-model="payload.login"
                        class="form-control auth-input"
                        required
                        type="text"
                        placeholder="Ваш логин"
                        :class="__validate('login', payload.login)">
                    <i class="fa input-hint"></i>
                  </div>
                </div>
              </div>

              <div class="col-12 px-0">
                <div class="input-group mb-4">
                  <div class="col-12 px-0">
                    <input
                        v-model="payload.password"
                        type="password"
                        class="form-control auth-input"
                        required
                        placeholder="Ваш пароль"
                        :class="__validate('password', payload.password)">
                    <i class="fa input-hint"></i>
                  </div>
                </div>
              </div>

              <div class="col-12 mb-3 px-0">
                <alert :message="response"/>
                <div class="clearfix"/>
              </div>

              <div class="col-12 px-0">
                <div class="col-12 mx-auto px-0">
                  <div class="green-btn-bg">
                    <button
                        name="isSubmitting"
                        class="btn auth-btn btn-green w-100 red-btn" :disabled="isSubmitting"
                        @click.prevent="UpdatePromotionUser">
                      Зарегистрироваться!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </b-modal>

    <b-modal
        id="clubModalWelcome"
        ref="clubModalWelcome"
        size="md"
        modal-class="main-modal page-main-block"
        ok-title="Закрыть"
        ok-only
        ok-variant="success modal-ok"
        header-class="text-white border-0"
        header-close-variant="orange"
        footer-class="border-0"
        hide-footer
        body-class="pt-0"
        centered>
      <div class="row mx-0 col-12 mx-auto px-0 px-md-3" v-if="promotionLobby">
        <div class="col-12 mx-auto py-3 px-0 text-center">
          <span class="text-dark-orange"> <strong>Поздравляем!</strong> </span>
          <br>
          <img :src="mag_icon" alt="MagnetBet" class="welcome-img">
          <br>
          <span> Вы получили бездепозитный бонус на сумму <strong
              class="text-dark-orange">{{ promotionLobby.amount }}руб.</strong>
            <br>Для продолжения игры, пожалуйста выберите аппарат. </span>
          <button class="btn red-btn w-100 text-white mt-4" @click.prevent="hideWelcomeText()">
            Играть
          </button>
        </div>
      </div>
      <div v-else>
        <div class="alert">
          <div class="col-12 resp-loader loading">
            <img :src="icon" alt="MagnetBet loading icon" class="narrow-loader-icon"
                 v-if="!setPromotionLobbyBalanceError">
            <span v-else>{{ setPromotionLobbyBalanceError }}</span>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
        id="clubModalRestore"
        ref="clubModalRestore"
        size="md"
        modal-class="main-modal page-main-block"
        ok-title="Закрыть"
        ok-only
        ok-variant="success modal-ok"
        header-class="text-white border-0"
        header-close-variant="orange"
        footer-class="border-0"
        hide-footer
        body-class="pt-0"
        centered>
      <div class="row mx-0 col-12 mx-auto px-0 px-md-3">
        <div class="col-12 text-center mb-4">
          <span class="denomination-hint">
            У Вас Активный Аппарат
            <br>
           <small>Вы можете продолжить игру или пройти регистрацию - остаток баланса появится на вашем личном аккаунте!</small>
          </span>
        </div>

        <div class="col-5 col-lg-5 px-0 mx-2 text-right mx-auto text-center"
             v-if="promotionLobby && promotionLobby.state != 1">
          <a class="btn gray-btn btn-block" @click="closeLobby()">Зарегистрироваться</a>
        </div>
        <div
            :class="[promotionLobby && promotionLobby.state != 1 ?'col-5 col-lg-5 px-0 mx-0 mx-lg-2 mx-auto mb-3': 'col-12 col-lg-12 mb-3']">
          <a class="btn red-btn w-100 border-0" @click="restoreLobby()"> Продолжить</a>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Alert from '@/components/Alert'

export default {
  name: 'PromotionLoginModal',
  components: {
    Alert
  },
  data() {
    return {
      icon: require('@/../public/images/icons/main/dots.svg'),
      mag_icon: require('@/../public/images/slot_machine/lobby_welcome.png'),
      showWelcomeText: false,
      payload: {
        login: '',
        password: '',
        token: ''
      },
      response: null,
      isSubmitting: false,

    }
  },
  async mounted() {
    if (this.token) {
      await this.$store.dispatch('getPromotionLobbyBalance', this.token)
    }

    if (this.$route.name === 'Promotion') {
      this.showWelcomeText = true
      this.$root.$emit('bv::show::modal', 'clubModalWelcome')
    }
  },
  computed: {
    token() {
      return this.$store.getters.getPromotionLobbiesToken
    },
    balance() {
      if (this.$store.getters.getPromotionLobbyBalance == null) {
        this.$store.dispatch('getPromotionLobbyBalance', this.token)

      }
      return this.$store.getters.getPromotionLobbyBalance
    },
    promotionLobby() {
      return this.$store.getters.getPromotionLobby
    },
    setPromotionLobbyBalanceError() {
      return this.$store.getters.getPromotionLobbyBalanceError
    }
  },
  methods: {
    hideWelcomeText() {
      this.showWelcomeText = false
      this.$root.$emit('bv::hide::modal', 'clubModalWelcome')
    },
    UpdatePromotionUser() {
      this.response = {status: 'loading'}
      this.isSubmitting = true
      this.payload.token = this.token
      this.axios.post(
          this.$_config.baseUrl + '/Api/UpdatePromotionUser', {
            authorize: this.payload
          }
      ).then((userData) => {
        // this.$store.dispatch('forceCollectTerminal', this.token)

        const data = userData.data
        this.isSubmitting = false
        this.response = data
        if (data.status === 'ok') {
          this.$store.dispatch('fetchUserData')
          this.response = {status: 'ok', msg: 'Добро пожаловать'}
          this.$store.dispatch('fetchMessages')
          this.$store.dispatch('mainSettings')

          this.$store.dispatch('fetchPromotionLobby', this.token)
          this.$root.$emit('bv::hide::modal', 'PromotionLoginModal')

          return this.$router.push({name: 'home'})
        }
      }).catch((err) => {
        if (err) {
          this.isSubmitting = false
          this.response = {status: 'error', msg: err.response.data.errors}
        }
      })
    },


    async closeLobby() {

      this.$root.$emit('bv::hide::modal', 'clubModalRestore')

      this.$root.$emit('bv::show::modal', 'PromotionLoginModal')
    },

    async restoreLobby() {

      this.$root.$emit('bv::hide::modal', 'clubModalRestore')
    },
  }
}
</script>

<style scoped>
.welcome-img {
  display: block;
  margin: 0 auto;
  max-width: 150px;
}
</style>