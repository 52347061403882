<template>
  <div class="col-12 py-3 py-lg-5 px-0" v-if="promotionLobby">
    <div class="row mx-0" v-if="!wantToPlay"
    >
      <div class="row mx-0 slider-container h-100 w-100" v-if="sm_images">
        <div class="col-12 px-0 mt-3 mt-md-5">
          <div class="row my-3 mx-0 w-100 justify-content-center" v-if="slotMachinesList">
            <div class="col-3 col-xl-2 px-0 text-center pt-0 float-left"
                 v-for="(sm, idx) in slotMachinesList" :key="'sm_' + idx" @click="slotMachineClick(sm.state, sm.id)">

              <img :src="sm.image" alt="Magnet.Bet - Slot Machine" :data-id="sm.id"
                   class="mw-100 slot-machine-img" :id="'machine_' + sm.id"
                   :class="sm.state ? 'free_machine' : 'busy_machine'">
            </div>
          </div>
          <div class="row my-3 mx-0 w-100 justify-content-center" v-else>
            <div class="alert alert-primary">
              <div class="col-12 resp-loader loading">
                <i class="fa fa-spinner fa-spin fa-fw"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0" v-else>
      <iframe
          id="lobbyLounchFrame"
          ref="lobbyLounchFrame"
          class="lobby-area"
          :src="promotionLobby.lobby_url"
          allowfullscreen
          style="width:100%; height: 800px; min-height: 100vh;">
      </iframe>
    </div>
    <b-modal
        id="clubModalBusy"
        ref="clubModalBusy"
        size="md"
        modal-class="main-modal page-main-block"
        ok-title="Закрыть"
        ok-only
        ok-variant="success modal-ok"
        header-class="text-white border-0"
        header-close-variant="orange"
        footer-class="border-0"
        hide-footer
        body-class="pt-0"
        centered>
      <div class="row mx-0 col-12 mx-auto px-0 px-md-3">
        <div class="col-12 mx-auto py-3 px-0 text-center">
          <span> Аппарат занят! </span>
        </div>
      </div>
    </b-modal>
    <b-modal
        id="clubModalFree"
        ref="clubModalFree"
        size="md"
        modal-class="main-modal page-main-block"
        ok-title="Закрыть"
        ok-only
        ok-variant="success modal-ok"
        header-class="text-white border-0"
        header-close-variant="orange"
        footer-class="border-0"
        hide-footer
        body-class="pt-0"
        centered>
      <div class="row mx-0 col-12 mx-auto px-0 px-md-3">
        <div class="col-12 mx-auto py-3 px-0 text-center">
          <span> Хотите играть Промо-Игру в игровом аппарате №{{ selectedSlotMachine }} ? </span>
          <button
              name="openConfirmModal"
              class="btn red-btn w-100 text-white mt-4"
              @click.prevent="startPromoGame()">
            Играть
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Promotion",
  props: ['token'],
  data() {
    return {
      selectedSlotMachine: null,
      wantToPlay: false,
    }
  },
  computed: {
    sm_images() {
      let machines = {}

      let folder = 'machines'
      if (window.innerWidth < 1200) {
        folder = 'machines_mobile'
      }

      for (let i = 1; i < 13; i++) {
          machines[i] = {
            free: require('@/../public/images/slot_machine/' + folder + '/basic/free_' + i + '.png'),
            busy: require('@/../public/images/slot_machine/' + folder + '/basic/busy_' + i + '.png')
          }
      }

      return machines
    },
    userData() {
      return this.$store.getters.userData
    },
    promotionLobby() {
      const lobby = this.$store.getters.getPromotionLobby
      if (lobby && lobby.state == 3) {
        this.$store.dispatch('getPromotionLobbyBalance', this.token)
        this.$root.$emit('bv::show::modal', 'PromotionLoginModal')
      }

      if (lobby && lobby.state == 4) {
        this.a()
      }
      return lobby
    },
    slotMachinesList() {
      let list = []
      for (let i = 0; i < 12; i++) {
        let state = Math.round(Math.random())

        list[i] = {
          id: i + 1,
          state: state,
          image: this.sm_images[i + 1][state === 0 ? 'busy' : 'free']
        }
      }
      return list
    }
  },
  async mounted() {
    if (this.userData) {
      this.$router.push({name: 'home'})
    }
    if (!this.token) {
      await this.$router.push({name: 'home'})
    }

    await this.$store.dispatch('fetchPromotionLobby', this.token)

    window.addEventListener('message', (e) => {
      if (e.data === "close-lobby-iframe" || e.message === "close-lobby-iframe") {
        this.$refs.lobbyLounchFrame.style.display = 'none'
        if (!this.userData) {
          this.$store.dispatch('getPromotionLobbyBalance', this.token)
          this.$root.$emit('bv::show::modal', 'PromotionLoginModal')
        }
      }
    })

  },
  methods: {
    startPromoGame() {
      this.wantToPlay = true
      this.$root.$emit('bv::hide::modal', 'clubModalFree')
      this.$store.dispatch('activatePromotionLobby', this.token)
      this.$store.dispatch('fetchPromotionLobby', this.token)
    },
    slotMachineClick(state, id) {
      if (state) {
        this.openModalFree(id)
      } else {
        this.openModalBusy()
      }
    },
    openModalFree(id) {
      this.selectedSlotMachine = id
      this.$root.$emit('bv::show::modal', 'clubModalFree')
    },
    openModalBusy() {
      this.$root.$emit('bv::show::modal', 'clubModalBusy')
    },
    UpdatePromotionUser() {
      this.response = {status: 'loading'}
      this.isSubmitting = true
      this.axios.post(
          this.$_config.baseUrl + '/Api/UpdatePromotionUser', {
            authorize: this.payload
          }
      ).then(async (userData) => {
        const data = userData.data
        this.isSubmitting = false
        this.response = data
        if (data.status === 'ok') {
          await this.$store.dispatch('fetchUserData')
          this.response = {status: 'ok', msg: 'Добро пожаловать'}

          await this.$store.dispatch('fetchMessages')
          await this.$store.dispatch('mainSettings')

          this.$router.push({name: 'home'})
        }
      }).catch((err) => {
        if (err) {
          this.isSubmitting = false
          this.response = {status: 'error', msg: err.response.data.errors}
        }
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.userData) {
      next()
    } else if (this.promotionLobby && this.promotionLobby != 4) {
      this.$root.$emit('bv::show::modal', 'clubModalRestore')
      next(false)
    } else {
      next()
    }
  }
}
</script>
