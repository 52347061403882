<template>
  <div class="deposit-container py-4 col-12 col-lg-9 col-xl-7  mx-auto mb-5">
    <div class="col-12 page-router ">
      <h6 class="text-white w-100 text-center">
        Главная /
        <span class="active-router-text">Депозит</span>
      </h6>
    </div>
    <h4 class="w-100 text-center text-white my-3 my-lg-5 page-main-title">
      Депозит
    </h4>

    <div id="deposit-area" class="col-12 mx-auto page-main-block deposit-main-area mt-5 px-0">
      <div class="page-title  text-center pt-3 pt-md-4 pb-2">
        <h5 class="text-muted mb-0 mb-lg-2">
          Пополни баланс и выиграй на
          <strong class="text-dark-red"> Казино Zevs777</strong>
        </h5>
      </div>

      <div v-if="depositMethods.length" class="row mx-0">
        <div class="col-12 col-sm-12 px-0 py-3 py-md-4">
          <div class="w-100 text-white px-3 mb-2 text-center">
            <span class="choose-method-txt">Выберите способ пополнения:</span>
          </div>
          <div class="col-12 px-0">
            <div class="col-12 px-0 col-lg-10 mx-auto row mx-0 justify-content-around">
              <div v-for="method in depositMethods" :key="method.id" class="paymethod-label pay-method-itm-style row"
                :class="[method.name === selectedMethod.name ? 'selected' : '']" @click="selectMethod(method)"
                v-lazy-container="{ selector: 'img', loading: require('@/../public/images/icons/game-images-loader.svg') }">
                <img :src="getPictureUrl(method.picture)" :data-src="getPictureUrl(method.picture)"
                  :alt="'MagnetBet казино депозит ' + method.name" class="pay-method-image m-auto">
                  <div  class="min-amount-label">
                    <span class="control-label">
                        Минимальное пополнение  за раз {{ method.min_amount }} руб.
                    </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="selectedMethod.id && userData" class="col-12 col-sm-12 col-md-10 col-lg-11 px-3 px-lg-2 mx-auto my-3">
          <span class="gray-color deposit-hint">Минимальный депозит {{ selectedMethod.minAmount }} Руб.</span><br>
          <span class="gray-color deposit-hint">Максимальный депозит за раз {{ depositSettings.max_amount }} Руб.</span>

          <b-form id="deposit-form" class="my-4 mx-0 deposit-form" method="POST" :action="$_config.payInUrl"
            @submit="payInFormSubmit">

            <b-row>
              <div class="col-12 px-0 input-group justify-content-center justify-content-lg-start">
                <button class="btn btn-red-to-orange deposit-amount-btn col-3 col-xl-2 mx-1 mx-lg-2 mb-3 mb-xl-1"
                  v-on:click.prevent="setDepositAmount(amount)" v-for="(amount, idx) in defaultDepositAmounts" :key="idx">
                  {{ amount }} руб
                </button>
              </div>
            </b-row>

            <b-row class="mx-0 mt-3">
              <div class="col-12 input-group px-0">
                <input id="depositAmount" ref="depositAmount" v-model="sum" class="form-control main-input"
                  placeholder="Другая сумма" type="text" required name="payin_amount">
              </div>
            </b-row>

            <b-row v-if="sum" class="mt-4">
              <div class="col-12 input-group ">
                <label class="col-form-label ">
                  Итого к оплате : {{ sum }} Руб {{ selectedMethod.name ? '- Метод : ' + selectedMethod.name : '' }}
                </label>
              </div>
            </b-row>

            <b-col cols="12" class="px-0 col-md-12 col-lg-11 mx-0 mx-lg-auto">
              <Alert :message="formResponse"></Alert>
            </b-col>

            <b-row class="mt-3 text-center mx-0 col-12 px-0">
              <div class="col-12 col-lg-6 mx-lg-auto px-0 px-lg-3">
                <div class="green-btn-bg">
                  <button class="btn red-btn w-100 text-white" type="submit">
                    Пополнить
                  </button>
                </div>
              </div>
              <div class="form-group row">
                <input v-model="selectedMethod.id" type="hidden" name="method">
                <input v-model="selectedMethod.module" type="hidden" name="selected_system">
              </div>
            </b-row>
          </b-form>
        </div>

        <div v-if="userData && userData.can_get_double_promotion" class="py-4 text-center mx-0 col-12">
          <p>
            <strong class="text-red-gradient">
              <i class="fa fa-thin fa-gift zoom-in-zoom-out"></i> +100% BONUS к первому депозиту <br>
            </strong>
            <br>
            <span class="text-white">
              Сделайте своё первое пополнение и получите <strong class="text-uppercase">удвоение</strong>
              своего депозита (удвоение будет только при первом пополнении)
            </span>
          </p>
        </div>

        <div v-if="userData" class="py-4 text-center mx-0 col-12 promo-activation-area">
          <div class="col-12 col-sm-10 col-md-7 col-lg-4 mx-auto px-0">
            <button name="ActivatePromoCode" class="btn btn-red-to-orange w-100"
              @click="$root.$emit('bv::show::modal', 'ActivatePromoCode')">
              Пополнение с промо-кода
            </button>
          </div>
        </div>
      </div>
      <div v-else class="row justify-content-center w-100 color-red mx-0 h-100 py-4">
        <i class="fa fa-spinner fa-spin fa-fw my-auto"></i>
      </div>
    </div>
    <ActivatePromoCode></ActivatePromoCode>
  </div>
</template>

<script>
import ActivatePromoCode from '../payments/ActivatePromoCode'
import Alert from '@/components/Alert'

export default {
  name: 'Deposit',
  data() {
    return {
      sum: null,
      selectedMethod: {
        id: null,
        module: '',
        name: '',
        minAmount: 50
      },
      formResponse: null,
      defaultDepositAmounts: [
        250, 500, 1000, 5000, 10000
      ]
    }
  },
  components: {
    Alert,
    ActivatePromoCode
  },
  computed: {
    userData() {
      return this.$store.getters.userData
    },
    depositMethods() {
      return this.$store.getters.getDepositMethods
    },
    depositSettings() {
      return this.$store.getters.getDepositSettings
    }
  },
  methods: {
    setDepositAmount(amount) {
      this.sum = amount
      document.getElementById('depositAmount').value = amount
    },
    getPictureUrl(url) {
      return this.$_config.baseUrl + url
    },
    selectMethod(selected) {
      if (!this.userData) {
        this.$root.$emit('bv::show::modal', 'loginModal')
        return this.$root.$emit('reasonToLogin', 'Для пополнения баланса нужно авторизоваться')
      }

      this.selectedMethod.name = selected.name
      this.selectedMethod.id = selected.id
      this.selectedMethod.module = selected.method_module
      this.selectedMethod.minAmount = selected.min_amount

      setTimeout(() => {
        document.querySelector('#depositAmount').scrollIntoView({ behavior: "smooth" })
        document.querySelector('#depositAmount').focus()
      }, 300)
    },
    payInFormSubmit(e) {
      if (!this.userData) {
        e.preventDefault()
        this.formResponse = { status: 'error', msg: 'Unauthorized' }
        setTimeout(() => {
          this.$router.push({ name: 'login' })
        }, 500)
        return
      }
      const payInAmount = parseFloat(this.$refs.depositAmount.value) || 0
      if (!payInAmount > 0) {
        e.preventDefault()
        this.formResponse = { status: 'error', msg: 'Пожалуйста, укажите правильую сумму' }
        return false
      }
      if (!this.selectedMethod.id || !this.selectedMethod.module) {
        e.preventDefault()
        this.formResponse = { status: 'error', msg: 'Пожалуйста выберите метод депозита' }
        return false
      }
      return true
    }
  },
  async mounted() {
    await this.$store.dispatch('fetchAvailableDepositMethods')
    window.scrollTo({
      top: 0
    })
  },
  watch: {
    sum() {
      const payInAmount = parseFloat(this.$refs.depositAmount.value) || 0
      if (payInAmount != this.sum) {
        this.sum = payInAmount
        return this.formResponse = { status: 'error', msg: 'Пожалуйста, укажите правильую сумму' }
      } else {
        return this.formResponse = {}
      }
    }
  }
}
</script>

<style scoped>
.page-router {
  margin-top: 100px;
}

.promo-activation-area {
  background-color: #151515;
}

.choose-method-txt,
.deposit-hint {
  font-size: 14px;
}

.deposit-form input {
  border-radius: 30px;
}

.paymethod-label {
  margin: 10px;
  background-color: #ffffff;
  height: 80px;
  width: 340px;
  padding: 5px;
  text-align: center;
  display: flex;
  color: black;
}
.min-amount-label{
    font-size: 13px;
    width: 50%;
    display: flex;
    justify-content: right;
    align-items: center;
}

.pay-method-itm-style {
  float: left;
  cursor: pointer;
  border-radius: 4px;
}

.pay-method-image {
  max-width: 50%;
  max-height: 100%;
  height: 80px;
  object-fit: scale-down;
}

.pay-method-itm-style.selected {
  border: 2px solid #b9051c;
}

.promocode-area {
  background-color: #171717;
}

@media (min-width: 700px) and (max-width: 1366px) {
  .paymethod-label {
    width: 190px;
    margin: 5px;
  }
}

@media (min-width: 320px) and (max-width: 700px) {
  .paymethod-label {
    height: 70px;
    width: 200px;
    margin: 5px;
  }
}
</style>

<style>
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

.text-red-gradient {
  background: -webkit-linear-gradient(#ea2f05, #b9051c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.zoom-in-zoom-out {
  margin-right: 5px;
  background: -webkit-linear-gradient(#ea2f05, #b9051c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: zoom-in-zoom-out 2s ease-out infinite;
  font-size: 22px;
}
</style>
