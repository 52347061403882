<template>
  <div class="col-12">
    <h1 class="text-center">
      Refund Policy
    </h1>
    <div class="col-10 mx-auto px-0">
      <a href="#" class="btn btn-secondary print_this_page" @click.prevent="onPrintPage">Print this page </a>
      <div class="notification__desc">
        <p>Terms of Service and Refund Policy&nbsp;</p>

        <p>These Terms of Service and Refund Policy (hereinafter "Policy"), located at https://zevs777.com, govern money
          deposits to Player Account, fees, payouts and refunds.&nbsp;</p>

        <p>Definitions&nbsp;</p>

        <p>1.1.“Website” – a website on the Internet, located at https://zevs777.com. All exclusive rights to the Site
          and its individual components (including software and design) belong to the Operator in full. The transfer of
          exclusive rights to the user is not subject to this Privacy Policy.&nbsp;<br>
          1.2.“Operator” – the website is operated by zevs777.com.<br>
          1.3.“User” – an individual, who uses the Website.&nbsp;<br>
          1.4.“Applicable law” – the laws of Curacao.&nbsp;<br>
          1.5.Funds” – money transferred to Player Account, the amount of which is displayed in the User’s Account, and
          that a User has the right to use for betting.&nbsp;<br>
          1.6.“Player Account” – a User’s personal page on the Website that contains personal information of the User
          and information about the amount of money the User may use for betting.&nbsp;<br>
          1.7.“Personal details”- a User’s personal information, provided by the User about him/herself at Registration
          or during the use of the Website.&nbsp;<br>
          1.8.“Details” – other information about the User (not included in the definition of Personal
          Details).&nbsp;<br>
          1.9.“Registration” – completion of the User Registration Form on the Website by filling out the required
          information, as well as choosing username and password.&nbsp;<br>
          1.10.“Registration form” - the form on the Website that a User must complete to register on the Website.&nbsp;<br>
          1.11.“Service(s)” - the ability of the User to place online bets on the Website on various competitions,
          provided on the Website.&nbsp;</p>

        <p>General Terms&nbsp;</p>

        <p>2.1 This Policy may be amended from time to time. The new Policy comes into effect, once published on the
          Website. Continued use of the Website following any changes shall be deemed as an acceptance of the Policy as
          per its amendment. If a User does not agree to the change, he/she should cease using the Website. This Policy
          is an integral part of the https://zevs777.com Website, as well as the "Bet Acceptance Policy".&nbsp;</p>

        <p>Legal requirements&nbsp;</p>

        <p>3.1. In no case and under no circumstances, any persons under the age of 18 or the age of consent, which
          legally permits to partake in betting under the law of a particular jurisdiction, shall be permitted to use
          the Services on the Website. Use of the Website Services by a person under the age of eligibility shall be
          considered a violation of the Policy. The Website reserves the right to request documentary proof of age at
          any time to ensure that no persons under the age eligibility are using the Website Services. The Website has
          the right to suspend or terminate the User's account and refuse any and all use of the Services, if the
          required proof of age is not provided, or the Website suspects that a person under the age of eligibility uses
          the Service.&nbsp;<br>
          3.2.It is the User’s sole responsibility to know whether betting is legal in his/her country of residence. The
          Operator makes no representation that the Website Services comply with the Applicable Law of the User’s
          jurisdiction. Users use the Services, provided by the Website, at their own discretion and accept the risk of
          liability by making a decision on whether the Use the Website Services is legal in accordance with the current
          legislation of the User’s jurisdiction.&nbsp;<br>
          3.3.The User is solely responsible for all taxes and fees applicable in connection with any winnings, derived
          from the use of the Website.&nbsp;</p>

        <p>Registration and Account Activation&nbsp;</p>

        <p>4.1.The User must register on the Website in order to be able to make online bets. When registering, User
          agrees to provide accurate and up-to-date information by filling out the registration form.&nbsp;<br>
          4.2.The User should enter registration details, all of which must be true and correct and match the legal
          name/last name. To verify the information provided, the Operator has the right to request the User’s ID at any
          time (including, but not limited to, a copy of the passport/ID card/or any bank cards used). If the User is
          unable to provide such information, the Operator has the right to suspend the User's account until the User
          provides such information, and/or permanently close the account in case of failure.&nbsp;<br>
          4.3.The User may open only one account on the Website. In the event additional/multiple User accounts are
          found, they will all be closed. The Operator also reserves the right to invalidate all transactions made from
          additional/multiple accounts.&nbsp;<br>
          4.4.The User agrees to notify the Website of any changes in personal or financial details that affect the use
          of the account. It is the User’s responsibility to notify the Website, when such changes occur. Any deliberate
          misinformation by the User may be considered as a violation of this Policy.&nbsp;<br>
          4.5.The User confirms that s/he is the rightful owner of funds in the account, and the funds, deposited into
          the account, have no criminal and/or illegal origin. All information, provided by the User during the
          registration process and/or at a later time, including during any transaction that requires depositing funds,
          is a true, relevant, correct and fully matches the name (names) on the credit/debit card(s) or other accounts,
          which will be used to deposit or withdraw funds to/from the User's account.&nbsp;<br>
          4.6.The Operator shall not accept funds from third parties, such as friends, relatives, partners and/or
          spouses. The User must deposit funds into his/her account only from the account and/or payment cards,
          registered in his/her name.&nbsp;<br>
          4.7.The Operator has the right to impose limits on the account for deposits and withdrawals in accordance with
          its internal security policy.&nbsp;<br>
          4.8.The User acknowledges and agrees that his/her account is not a bank account and, therefore, by no means of
          insurance, warranty or other protection tools from deposit or banking insurance, as well as any similar
          security systems are applied. No interest is charged on the funds deposited into the User's account.&nbsp;<br>
          4.9.Henceforth, the User is solely responsible for maintaining the confidentiality of his/her password, as
          well as for all activities and transactions in the User’s account, including for all damages to the Account,
          incurred by any third party.&nbsp;<br>
          4.10.The User agrees to immediately notify the Website of any unauthorized access to the account and/or any
          breach of security. Upon request, User agrees to provide evidence of such unauthorized access. Under no
          circumstances shall the Operator be liable for any damages incurred by the User as a result of improper use of
          username and password by another person or for unauthorized access to User's account, regardless of whether
          they were authorized Users or carried out without the User's knowledge.&nbsp;</p>

        <p>Deposits And Withdrawals Of Funds&nbsp;</p>

        <p>5.1.The Operator has the right to use third parties to process electronic payments and/or financial
          institutions to process the User’s payments.&nbsp;<br>
          5.2.The Operator uses only those methods of deposits/withdrawals of funds that are listed on the Website.&nbsp;<br>
          5.3.When using a specific method of payment, the User has to make sure that the account in the system is
          registered in his/her name. We shall not have any liability to the User, whose name is different from that
          registered on Website.&nbsp;<br>
          5.4.The User agrees not to abandon any prior transactions/or deny or reverse any payments made by them, which
          may cause a refund by a third party, in order to avoid any legal liability.&nbsp;<br>
          5.5.In case of suspicious or fraudulent deposits, including the use of stolen credit cards and/or any other
          activity of fraudulent or illegal nature (including any refunds or cancellations of payments), the Operator
          has the right to request additional information about the client and/or the origin funds and reserves the
          right to terminate the User's account, cancel any payments made and recover any winnings in the case of
          failure to provide additional information/documents in full and/or non-compliance with the submitted
          documents/information. We have the right to inform the relevant authorities and/or entities (including credit
          agencies) of any payment fraud or other illegal activity.&nbsp;<br>
          5.6.To withdraw funds from the account, the User should make a request to withdraw funds from the Personal
          Account. To do this, the User must select one of the payment systems, listed on the Website, and follow the
          appropriate link.&nbsp;<br>
          5.7.The User may request to withdraw funds from the account at any time, provided that:&nbsp;<br>
          5.7.1.all payments are transferred to the User’s account have been checked for the absence of illegal actions,
          and no payment has not been revoked or otherwise canceled;&nbsp;<br>
          5.7.2.the details section in the user profile is filled out.&nbsp;<br>
          5.8.If the requested amount exceeds one thousand dollars (USD 1,000.00), the Operator has the right to carry
          out the identification procedure.&nbsp;<br>
          5.9.Withdrawal requests are processed by the financial department on a first-come first-served
          basis.&nbsp;<br>
          5.10.In the event that the payment system, used for deposits, has no technical possibility for withdrawals,
          the appropriate withdrawal system will be agreed by the Operator and User. In this case, the details of the
          payment system should coincide with the personal information, specified by the User during registration. The
          User is solely responsible for the accuracy of the information used in the withdrawal request.&nbsp;<br>
          5.11.Operator is not responsible for activity (errors, delays) any third service providers with which the user
          performs input / output of cash.&nbsp;<br>
          5.12.The User may choose to close the account at any time. To do so, a request should be sent by e-mail.
          Before closing the account all available funds will be paid out in accordance with the Rules.&nbsp;</p>

        <p>Online Betting&nbsp;</p>

        <p>6.1.The User may use the money only for betting or playing in slots machines. All other transactions
          (transfer of funds to other payment systems, payment of various services, etc.) are prohibited.&nbsp;<br>
          6.2.The Operator reserves the right to refuse to accept bets from any individuals without an explanation and
          to refuse registration on the Website and/or delete the account in the event of apparent violations by the
          User).&nbsp;</p>

        <p>Prohibited Activities&nbsp;</p>

        <p>7.1.The use of abusive or aggressive style of communication or images; profanity, threats, belittling, or
          violent actions towards the players and staff of the Website is prohibited.&nbsp;<br>
          7.2.The Users agrees not to break in or try to break in and/or access or otherwise bypass the security system
          of the Website. If we have a suspicion that a User has tried or is trying to break in, access or otherwise
          bypass our security system or software, we will be forced to immediately ban the User’s the access to the
          Website and terminate the User’s account; we also reserve the right to notify the appropriate authorities.&nbsp;
        </p>

        <p>System Errors&nbsp;</p>

        <p>8.1.In the event of any system failure or error on the Website, the Operator will attempt to resolve the
          issue as soon as possible. We do not assume any responsibility for the failure of information technology
          caused by the equipment work, used by the User or other players to access the Website, as well as failures of
          the User’s Internet service Provider or Internet service Provider of other users.&nbsp;</p>

        <p>Policy Violation&nbsp;</p>

        <p>9.1.The User agrees to reimburse for any and all costs (including legal fees) that may arise as a result of
          breach by the Use in full:&nbsp;<br>
          9.1.1.Terms of Use;&nbsp;<br>
          9.1.2.Rights of Third Parties;&nbsp;<br>
          9.1.3.Use of access to Services by any other person, employing the User’s identification, with or without the
          his/her permission; or&nbsp;<br>
          9.1.4.Acceptance of any winnings obtained this way.&nbsp;<br>
          9.2.If the User commits any breach of Policy, the Operator reserves the right, but is not obligated
          to:&nbsp;<br>
          9.2.1. send the User a notice (using your contact details) of such breach, requesting a remedy;&nbsp;<br>
          9.2.2. suspend the User's account;&nbsp;<br>
          9.2.3.terminate the User's account, with or without prior notice;&nbsp;<br>
          9.2.4. withdraw the User’s payout, bonuses or winnings, acquired as a result of any serious violation(s).&nbsp;
        </p>

        <p>Complaints And Notifications&nbsp;</p>

        <p>10.1.If the User wishes to file a complaint against the Website, s/he should first contact Customer Service
          as soon as reasonably possible.&nbsp;<br>
          10.2.In the case of any dispute, the User agrees that the account stored on the server will act as the final
          evidence in determining the outcome of any claim.&nbsp;</p>

        <p>Links&nbsp;</p>

        <p>11.1.The Website may contain links to other websites that are not controlled by the Operator and not
          mentioned in the Policy. The Operator is neither responsible for the content of any third party websites, acts
          or omissions of their respective owners, nor for the content of third party advertisements and sponsorship on
          those websites. Hyperlinks to other websites are provided for informational purposes only. The User shall use
          any such links at their own risk.&nbsp;</p>

        <p>Contact us</p>

        <p>If you have any questions about the Website, services, payment methods, payouts and refunds, please contact
          our online customer service or send an email to info@magnet.bet. We will be happy to assist you!&nbsp;</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RefundPolicy",
  methods: {
    onPrintPage() {
      window.print()
    }
  }
}
</script>

<style scoped>
.print_this_page {
  position: absolute;
  right: 0;
}
</style>
