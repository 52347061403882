<template>
  <div id="app">
    <div class="mx-auto main-container col-12 px-0">
      <MainVersionLoader />
      <LoginPopUp v-if="!userData"></LoginPopUp>
      <PromotionLoginModal v-if="!userData"></PromotionLoginModal>
      <AliveGame v-if="userHasAliveGame && allowAliveGamePopup"></AliveGame>
      <modal-alert></modal-alert>
      <NewMessagesPopUp v-if="newMessages"></NewMessagesPopUp>
      <FlashMessages v-if="flashMessages"></FlashMessages>
      <CustomMessages></CustomMessages>

      <FreeRoundSelectorNew
          v-if="!userHasAliveGame && allowFreeRoundPopup != false">
      </FreeRoundSelectorNew>
      <ChatForConsulting />
    </div>
  </div>
</template>

<script>

import LoginPopUp from './components/user/LoginPopUp'
import AliveGame from './components/games/AliveGame'
import ModalAlert from '@/components/ModalAlert'
import NewMessagesPopUp from '@/components/user/NewMessagesPopUp'
import FlashMessages from '@/components/main/FlashMessages'
import CustomMessages from '@/components/main/CustomMessages'
import MainVersionLoader from '@/components/MainVersionLoader'
import PromotionLoginModal from "@/components/main/PromotionLoginModal";
import FreeRoundSelectorNew from './components/games/FreeRoundSelectorNew';
import ChatForConsulting from '@/components/main/ChatForConsulting'
export default {
  name: 'App',
  components: {
    PromotionLoginModal,
    LoginPopUp,
    AliveGame,
    ModalAlert,
    NewMessagesPopUp,
    FlashMessages,
    CustomMessages,
    ChatForConsulting,
    MainVersionLoader,
    FreeRoundSelectorNew
  },
  data() {
    return {
      currentPath: null,
      allowAliveGamePopup: false,
      allowFreeRoundPopup: false
    }
  },
  created() {
    let settings = this.$store.getters.getMainSettings

    if (settings && this.alive) {
      this.allowAliveGamePopup = true
    }

    if (settings && this.freeRound) {
      this.allowFreeRoundPopup = true
    }
  },
  computed: {
    alive() {
      return this.$store.getters.aliveGames
    },
    freeRound() {
      return this.$store.getters.freeRound
    },
    userData() {
      return this.$store.getters.userData
    },
    userHasAliveGame() {
      const userData = this.$store.getters.userData
      if (!userData) {
        return false
      }
      const aliveGames = this.$store.getters.aliveGames
      return !!(aliveGames && aliveGames.length)
    },
    newMessages() {
      const userData = this.$store.getters.userData
      if (!userData) {
        return false
      }
      const newMessages = this.$store.getters.newMessages

      return !!(newMessages && newMessages.length)
    },
    flashMessages() {
      const flashMessages = this.$store.getters.getFlashMessages
      return !!(flashMessages && flashMessages.messages && flashMessages.messages.length)
    },
    isGamePage() {
      return this.$route.name === 'LaunchGame' || this.$route.name === 'LaunchLiveGame' || this.$route.name === 'LaunchGameMobile'
    }
  },
  updated() {
    let iframes = document.querySelectorAll('.widget-visible iframe')
    if (typeof iframes !== 'undefined') {
      let iframeForButton = document.querySelector('.widget-visible iframe')
      if (iframeForButton) {
        let buttonFromIframe = iframeForButton.contentDocument.querySelector('.font-lato button')
        if (buttonFromIframe) {
          buttonFromIframe.click()
        }
      }
    }
  },
  methods: {
    scrollTop() {
      window.scrollTo({top: 0})
    },
    isMobile() {
      return this.$_config.environment.mobile
    }
  },
  mounted() {
    const header = document.getElementById('header')
    const footer = document.getElementById('footer')
    const content = document.getElementById('main-content')
    if (typeof header !== 'undefined' && typeof footer !== 'undefined' && typeof content !== 'undefined') {
      const contentNeedHeight = window.innerHeight - footer.offsetHeight - header.offsetHeight
      content.style.minHeight = contentNeedHeight + 'px'
    }
  },
  watch: {
    async $route(to) {

      // const collapseBtn = document.getElementById('nav-collapse-button')
      // collapseBtn.click()

      const collapseBtn = document.getElementById('nav-collapse-button')
      const collapseState = collapseBtn.getAttribute('aria-expanded')
      if (collapseState === 'true') {
        collapseBtn.click()
      }

      if (to.name === "LaunchGameMobile") {
        let mainElem = document.getElementById("main-content")
        mainElem.style.height = "100vh"
        mainElem.style.width = "100vw"
        mainElem.style.position = "fixed"
        mainElem.style.top = "0"
        mainElem.style.zIndex = "889"
      }
      // let previousRouteName = previousRoute.name

      if (!this.isGamePage) {
        await this.$store.dispatch("checkForAliveGame")
        await this.$store.dispatch("checkUserFreeRound")
      }

      // if (previousRouteName === "LaunchGame" || previousRouteName === "LaunchGameMobile") {
      //   if (this.allowAliveGamePopup) {
      //     this.$store.dispatch("checkForAliveGame")
      //   }
      // }
      // const collapseState = collapseBtn.getAttribute('aria-expanded')
      // if (collapseState === 'true') {
      //   collapseBtn.click()
      // }
      document.title = to.meta.title ? to.meta.title + ':MagnetBet' : 'Казино MagnetBet'

    },
    alive: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.allowAliveGamePopup = true
        }
      }
    },
    freeRound: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.allowFreeRoundPopup = true
        }
      }
    }
  }
}
</script>
