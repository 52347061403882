<template>
  <div class="py-3 py-lg-5 col-12">
    <div class="col-12 page-router">
      <h6 class="text-white w-100 text-center">
        Главная / <span class="active-router-text">Бонусы</span>
      </h6>
    </div>
    <div class="row mx-0 col-12 col-lg-11 col-xl-10 mx-auto px-0 mt-5" v-if="bonuses">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 bonus-area" v-for="(bonus, index) in bonuses" :key="index">
        <img :src="getIcon(bonus.image)" :alt="bonus.title" class="bonus-item mb-5" @click="openModal(bonus)">
        <div class="bonus-description-area ">
          <div class="d-table-cell v-middile text-center">
            <span class="bonus-title">{{ bonus.title }}</span> <br>
            <button @click="openModal(bonus)" class="btn red-btn bonus-button mt-3 px-4 py-2">Подробнее</button>
          </div>
        </div>
      </div>

      <div class="col-12 px-0 mt-4">
        <h3 class="text-center mt-3 mb-5 ">Прикрепи соц-сети и получи кэш!</h3>
        <div class="row mt-5 justify-content-center" v-if="userData">
          <div class="clearfix"></div>
          <div class="col-6 col-md-4 col-lg-3 col-xl-2 social-network-itm text-center"
          v-for="(bonus, idx) in confirmBonus" :key="idx">
            <img :src="getIcon(bonus.img)" alt="Magnet.Bet - Bonuses">

            <p class="mt-4">
              Подтверди и получи {{ bonus.bonus_confitm }} Руб.
            </p>


            <p class="mb-5 mt-4 verify-btn" v-if="idx === 'email'">
              <span class="btn text-white red-btn"
                v-if="userData.email_confirmed == 0 && (userData.sum_replenishment >= 2500 || userData.isPromo)">
                <a @click.prevent="confirmEmail">
                  <i class="fa fa-link"></i> Получить 25 Руб.
                </a>
              </span>

              <span v-else-if="userData.email_confirmed == 0 && userData.sum_replenishment < 2500 && !userData.isPromo"
                class="btn text-white red-btn disable_bonus" @click="disableBonusinfo()">
                <i class="fa fa-link"></i> Получить 25 Руб.
              </span>

              <span v-else class="btn text-white red-btn disable_bonus">
                <i class="fa fa-link"></i> Подтвержден
              </span>
            </p>

            <p class="mb-5 mt-4 verify-btn" v-else-if="idx === 'phone'">
              <span class="btn text-white red-btn"
                v-if="userData.phone_confirmed == 0 && (userData.sum_replenishment >= 2500 || userData.isPromo)">
                <a @click.prevent="confirmPhone">
                  <i class="fa fa-link"></i> Получить 25 Руб.
                </a>
              </span>

              <span v-else-if="userData.phone_confirmed == 0 && userData.sum_replenishment < 2500 && !userData.isPromo"
                class="btn text-white red-btn disable_bonus" @click="disableBonusinfo()">
                <i class="fa fa-link"></i> Получить 25 Руб.
              </span>

              <span v-else class="btn text-white red-btn disable_bonus">
                <i class="fa fa-link"></i> Подтвержден
              </span>
            </p>

            <p class="mb-5 mt-4 verify-btn" v-else>
              <span class="btn text-white red-btn"
                v-if="userData.selfieData.status == -1 && userData.verified == 1 && userData.sum_replenishment >= 2500">
                <a @click.prevent="confirmSelfie">
                  <i class="fa fa-link"></i> Получить 250 Руб.
                </a>
              </span>

              <span class="btn text-white red-btn"
                v-else-if="userData.selfieData.status == -1  && userData.sum_replenishment >= 2500">
                <a @click="disableSelfieBonus()">
                  <i class="fa fa-link"></i> Получить 250 Руб.
                </a>
              </span>

              <span class="btn text-white red-btn"
                v-else-if="userData.selfieData.status == 0 && userData.sum_replenishment >= 2500">
                В обработке
              </span>

              <span class="btn text-white red-btn"
                v-else-if="userData.selfieData.status == 2 && userData.verified == 1 && userData.sum_replenishment >= 2500">
                Отказано 
                <br />
                <a @click.prevent="confirmSelfie">
                  <i class="fa fa-link"></i> Получить 250 Руб.
                </a>
              </span>

              <span class="btn text-white red-btn"
                v-else-if="userData.selfieData.status == 2  && userData.sum_replenishment >= 2500">
                Отказано
                <br />
                <a @click="disableSelfieBonus()">
                  <i class="fa fa-link"></i> Получить 250 Руб.
                </a>
              </span>

              <span
                v-else-if="userData.selfieData.status == -1 && userData.sum_replenishment < 2500"
                class="btn text-white red-btn disable_bonus" @click="disableSelfieBonus()">
                <i class="fa fa-link"></i> Получить 250 Руб.
              </span>

              <span v-else class="btn text-white red-btn disable_bonus">
                <i class="fa fa-link"></i> Подтвержден
              </span>
            </p>
          </div>
        </div>

        <div class="row mt-5 justify-content-center" @click="checkUser()" v-else>
          <div class="clearfix"></div>
          <div class="col-6 col-md-4 col-lg-3 col-xl-2 social-network-itm text-center"
           v-for="(bonus, idx) in confirmBonus" :key="idx">
            <img :src="getIcon(bonus.img)" alt="Zevs777 - Bonuses">

            <p class="mt-4">
              Подтверди и получи {{ bonus.bonus_confitm }} Руб.
            </p>
          </div>
        </div>
        <ConfirmSelfie :user-data="userData"></ConfirmSelfie>
        <ConfirmEmail :user-data="userData"></ConfirmEmail>
        <ConfirmPhoneViaCall :user-data="userData" v-if="mainSettings && mainSettings.phoneActionsMethod === 'call'">
        </ConfirmPhoneViaCall>
        <ConfirmPhone :user-data="userData" v-else></ConfirmPhone>
      </div>
    </div>
    
    <b-modal v-if="selectedBonus" id="bonusModal" ref="bonusModal" size="md" modal-class="main-modal page-main-block"
      ok-title="Закрыть" ok-only ok-variant="success modal-ok" header-class="text-white border-0"
      header-close-variant="orange" title-class="text-center underlined-muted-title" footer-class="border-0" hide-footer
      body-class="pt-0" centered>
      <div class="row mx-0 col-12 mx-auto">
        <div class="col-12 mx-auto py-3 px-0">
          <div class="col-12 px-0">
            <div class="col-12 mx-auto pt-3 pb-2 px-0">
              <h4 class="text-center">{{ selectedBonus.title }}</h4>
            </div>
            <div class="col-12 mx-auto pt-3 pb-2 px-0">
              <p class="text-center fs-14" v-html="selectedBonus.description"></p>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    
    <b-modal id="bonuse-disable-info" ref="bonuse-disable-info" size="md" modal-class="main-modal page-main-block"
      ok-title="Закрыть" ok-only ok-variant="success modal-ok" header-class="text-white border-0"
      header-close-variant="orange" title-class="text-center underlined-muted-title" footer-class="border-0" hide-footer
      body-class="pt-0" centered>
      <div class="row mx-0 col-12 mx-auto">
        <div class="col-12 mx-auto py-3 px-0">
          <div class="col-12 px-0">
            <div class="col-12 mx-auto pt-3 pb-2 px-0">
              <p class="text-left" v-if="selfieBonusDisableInfo">
                Фото подтверждение
                <br /><br />
                Для игроков казино Magnet.Bet
                <br /><br />
                Бонус доступен тем игрокам, которые пополнили свой счет на общую 
                сумму не менее 2500 руб. с момента регистрации.
                <br /><br />
                Для подробной информации обратитесь к менеджеру сайта через  <a href="https://t.me/zevs777manager"
                  target="_blank">@zevs777manager</a>.
              </p>
              <p v-else-if="emailBonusVerified">
                Напишите свой логин на почту <a  href="mailto:magnetbet@yandex.ru"> magnetbet@yandex.ru</a> для получения 100₽, после ручной проверки менеджер выдаст бонус в течении 24 часов.
              </p>
              <p v-else-if="emailBonusNotVerified">
                Для использования бонуса необходима полная верификация (обычная и селфи)
              </p>
              <p v-else-if="cashbackText">Каждый месяц 1-ого и 15-ого числа вам будет начислен возврат денег в случае
                проигрыша. Данный бонус начисляется автоматическим образом.</p>
              <p class="text-center" v-else>Бонус будет доступен если у Вас пополнение от 2500 рублей и выше</p>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>



<script>
import ConfirmEmail from "@/components/user/actions/ConfirmEmail";
import ConfirmPhoneViaCall from '@/components/user/actions/ConfirmPhoneViaCall'
import ConfirmPhone from '@/components/user/actions/ConfirmPhone'
import ConfirmSelfie from "../user/actions/ConfirmSelfie.vue";

export default {
  components: {
    ConfirmEmail,
    ConfirmPhone,
    ConfirmPhoneViaCall,
    ConfirmSelfie
  },
  data() {
    return {
      loader: require('@/../public/images/icons/small-loader.svg'),
      selectedBonus: null,
      selfieBonusDisableInfo: false,
      emailBonusNotVerified:false,
      emailBonusVerified:false,
      cashbackText: false,
      confirmBonus:{
        phone:{
          title:'Тел.Номер',
          img:'bonus_phone.png',
          bonus_confitm:25,
        },
        email:{
          title:'Емайл',
          img:'bonus_email.png',
          bonus_confitm:25,
        },
        selfie:{
          title:'Селфи',
          img:'selfie_bonus.png',
          bonus_confitm:250,
        },
      },
      bonuses: {
        cashback: {
          image: 'cashback.png',
          title: 'КЭШБЭК',
          description: 'Каждый месяц 1-ого и 15-ого числа автоматически происходит возврат денег в случае проигрыша.'
        },
        double: {
          image: 'double.png',
          title: 'УДВОЕНИЕ ДЕПОЗИТА',
          description: 'Удвоение депозита производится раз в месяц, максимальная сумма депозита до 5000 руб. Воспользоваться бонусным предложением от казино Zevs777 можно один раз в 30 дней.' +
            'Для получения бонуса необходимо внести депозит от 100 руб. Последующее получение удвоения депозита производится только после 30 дней с момента получения последнего удвоения.' +
            'Сумма пополнения удваивается со стороны Менеджера (для получения удвоения, перед внесением депозита обратитесь к Менеджеру сайта через <a target="_blank" href="https://t.me/zevs777manager">Telegram</a>).'
        },
        promocode: {
          image: 'promocode.png',
          title: 'ПРОМОКОД ДЛЯ НОВЫХ КЛИЕНТОВ',
          description: 'Для игроков казино Zevs777, в ознакомительных целях выдается промокод. Это одноразовый код, по которому игроку начисляются бездепозитные денежные средства. Сами бонусные средства не подлежат выводу. Можно снять только то что выиграли с бонуса. При выигрыше с одного бонуса можно снять любую сумму до 5000руб., остальная сумма будет списана и аннулирована. Средства, полученные через ПРОМОКОД, но не использованные в течение двух недель, будут аннулированы. Для подробной информации обратитесь к менеджеру сайта через <a href="https://t.me/zevs777manager" target="_blank">Telegram</a>.'
        },
        telegram: {
          image: 'telegram.png',
          title: 'TELEGRAM КАНАЛ',
          description: 'Подписывайтесь на Telegram канал <a href="https://t.me/zevs777casino" target="_blank">@zevs777casino</a>. На канале часто разыгрываются бонусы и проводятся акции, также Вы получите все новости касаемые с сайтом. ' +
            'Игроки Zevs777 могут выиграть промокоды в Телеграмме канале. Это одноразовый код, по которому игроку начисляются бездепозитные денежные средства. Сами бонусные средства не подлежат выводу. Можно снять только то что выиграли с бонуса. ПРОМОКОД можно выиграть приняв участие в розыгрышах на канале <a href="https://t.me/zevs777casino" target="_blank">@zevs777casino.</a>'
        },
        wheel: {
          image: 'koleso.png',
          title: 'КОЛЕСО Zevs777',
          description: 'Активные игроки имеют шанс каждый день выиграть до 1000 руб.!' +
            'Колесо МАГНИТ доступен один раз в день и активируется при пополнении от 20000 руб. в течение месяца.(месяц считается до текущего дня).' +
            'К примеру: если Вы прокрутили колесо в ' +
            new Date().toLocaleDateString('en-ca') + ` то следующий прокрут будет доступен после ${this.getTomorrowDate()} . Для доступа к Колесу Фортуны сегодня, сумма Ваших пополнений за ${new Date().toLocaleDateString('en-ca')} до ${this.getTomorrowDate()} должна быть больше 20000 руб.`
        }
      }
    }
  },
  computed: {
    userData() {
      return this.$store.getters.userData
    },
    socialNetworkBonusEnabled() {
      return this.$store.getters['getMainSettings'].socialNetworkBonusEnabled
    },
    mainSettings() {
      return this.$store.getters.getMainSettings
    },
  },
  methods: {
    getIcon(icon) {
      return require('@/../public/images/bonuses/' + icon)
    },
    openModal(bonus) {
      this.selectedBonus = bonus
      this.$root.$emit('bv::show::modal', "bonusModal")
    },
    getTomorrowDate() {
      let today = new Date()
      let tomorrow = new Date()
      tomorrow.setDate(today.getDate() + 1)
      return tomorrow.toLocaleDateString('en-ca')
    },
    confirmPhone() {
      this.$root.$emit('bv::show::modal', 'confirmPhoneModal')
    },
    confirmEmail() {
      this.$root.$emit('bv::show::modal', 'confirmEmailModal')
    },
    confirmSelfie() {
      console.log(this.userData);
      this.$root.$emit('bv::show::modal', 'confirmSelfieModal')
    },
    disableBonusinfo() {
      this.$root.$emit('bv::show::modal', 'bonuse-disable-info')
      this.selfieBonusDisableInfo = false
      this.cashbackText = false
    },
    disableSelfieBonus() {
      this.cashbackText = false
      this.selfieBonusDisableInfo = true
      this.$root.$emit('bv::show::modal', 'bonuse-disable-info')
    },
    checkUser() {
      if (!this.userData) {
        this.$root.$emit('bv::show::modal', 'loginModal')
        return this.$root.$emit('reasonToLogin', 'Действия доступно авторизованным клиентам')
      }
    }
  },
  mounted() {
    window.scrollTo({
      top: 0
    })
  },
}
</script>

<style scoped>
.fs-14 {
  font-size: 14px;
}

.v-middile {
  vertical-align: middle;
}

.bonus-area {
  position: relative;
  width: auto;
  max-width: 100%;
  height: 100%;
}

.bonus-item {
  height: 200px;
  width: 100%;
  object-fit: contain;
  border-radius: 7px;
  box-shadow: 0 0 10px 2px #fff;
  cursor: pointer;
}

.bonus-title {
  font-weight: 600;
}

.bonus-area:hover .bonus-description-area {
  height: 200px;
  background: #0a090f73;
  top: 0;
  left: 0;
  position: absolute;
  display: table;
  width: calc(100% - 30px);
  margin: 0 15px;
}


.bonus-description-area {
  display: none;
  border-radius: 7px;
  cursor: pointer;
}

.page-router {
  margin-top: 100px;
}
</style>
