<template>
  <div v-if="userData" class="profile-content col-12 row mx-0 justify-content-between px-0">
    <div class="col-12 col-sm-5 col-md-6 col-lg-5 col-xl-4">
      <div class="col-12 col-xl-11 mx-auto profile-description py-4 px-4">
        <h6 class="w-100 text-muted text-center">
          Добро пожаловать {{ userData.login }}
        </h6>
        <div class="col-12 px-0" v-if="userData.level == 1">
          <img src="../../../public/images/icons/levels/user-icon.png" alt="MagnetBet user profile icon" class="profile-user-icon mw-100">
        </div>
        <div class="col-12 px-0">
          <p class="w-100">
            Статус : {{ userData.levelTitle }}
          </p>
        </div>
        <div class="col-12 px-0">
          <p class="w-100">
            Баланс: <br> {{ userData.balance }} Руб
          </p>
        </div>
        <div class="col-12 px-0">
          <router-link :to="{ name: 'deposit' }">
            <button class="btn red-btn w-100" name="deposit">
              Пополнить баланс
            </button>
          </router-link>

        </div>
      </div>
      <div class="col-12 col-lg-8 mx-auto py-2 px-0">
        <a class="text-muted" href="#" @click.prevent="logout">
          <small>
            Выход |
            <i v-if="!loggingOut" class="fa fa-arrow-right"></i>
            <i v-else class="fa fa-spinner fa-spin small-spin text-white"></i>
          </small>

        </a>
      </div>
    </div>
    <div class="col-12 col-sm-7 col-md-6 col-lg-7 col-xl-7">
      <div v-if="userData" class="user-info bg-transparent text-white">
        <b-list-group>
          <b-list-group-item class="bg-transparent">
            <div class="row">
              <div class="col-12 text-left text-muted">
                <small>Логин</small>
              </div>
              <div class="col-12 text-left">
                <span>{{ userData.login }}</span>
              </div>
            </div>
          </b-list-group-item>

          <b-list-group-item class="bg-transparent">
            <div class="row">
              <div class="col-12 text-left text-muted">
                <small>Ваше имя</small>
              </div>
              <div class="col-12 text-left">
                <span v-if="userData.name">{{ userData.name }}</span>
                <form class="w-100" v-else>
                  <Alert :message="updateNameResponse"></Alert>
                  <fieldset class="form-group row m-0">
                    <div class="col-6 col-md-5 col-lg-8 px-0">
                      <input type="text" placeholder="Ваше имя *" required aria-required="true"
                        class="form-control main-input" v-model="usersName">
                    </div>
                    <div class="col-6 col-lg-4 px-0 text-lg-center">
                      <b-button v-if="userData.name == ''" class="btn float-right red-btn"
                        @click.stop.prevent="updateUsersName">
                        Изменить
                      </b-button>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </b-list-group-item>

          <b-list-group-item class="bg-transparent">
            <div class="row">
              <!--              <div class="col-12 text-left text-muted">-->
              <!--                <small>Телефон <span class="text-success" v-if="!userData.phone_confirmed">Кэш за подтверждение 25 руб</span></small>-->
              <!--              </div>-->
              <div class="col-12 text-left">
                <span class="user-phone-text">
                  <input v-mask="'+#(###)-###-##-##'" type="text" class="user-phone main-input text-left border-0 pt-2"
                    disabled :value="userData.phone">
                </span>
                <!--                <small class="float-right text-muted" v-if="!userData.phone_confirmed && userData.sum_replenishment <= 0">-->
                <!--                  Для активации бонуса необходимо сделать свой первый депозит.-->
                <!--                </small>-->
                <b-button v-if="userData.phone_confirmed == 0" name="confirmPhone" if="!userData.phone_confirmed"
                  class="red-btn float-right" @click.prevent="confirmPhone">
                  Подтвердить
                </b-button>

              </div>
            </div>
          </b-list-group-item>

          <b-list-group-item class="bg-transparent">
            <div class="row">
              <div class="col-12 text-left text-muted">
                <small>Пароль</small>
              </div>
              <div class="col-12 text-left">
                <span class="pt-2 d-inline-block">**********</span>
                <b-button name="changePassword" v-if="userData.phone_confirmed || userData.email_confirmed"
                  class="red-btn float-right" @click.prevent="changePassword">
                  Изменить
                </b-button>
                <small v-else class="col-9 px-0 d-inline-block float-right text-muted">
                  Пароль можно изменить только после подтверждения номера телефона или адреса электронной почты
                </small>
              </div>
            </div>
          </b-list-group-item>

          <b-list-group-item class="bg-transparent">
            <div class="row">
              <!--              <div class="col-12 text-left text-muted">-->
              <!--                <small>E-mail <span class="text-success" v-if="!userData.email_confirmed">Кэш за подтверждение 25 руб</span></small>-->
              <!--              </div>-->
              <div class="col-12 text-left">
                <span class="d-inline-block pt-2 user-email-text ">{{ userData.email }}</span>
                <!--                <small class="float-right text-muted" v-if="!userData.email_confirmed && userData.sum_replenishment <= 0">-->
                <!--                  Для активации бонуса необходимо сделать свой первый депозит.-->
                <!--                </small>-->
                <b-button v-if="userData.email_confirmed == 0" name="confirmEmail" if="!userData.email_confirmed"
                  class="red-btn float-right" @click.prevent="confirmEmail">
                  Подтвердить
                </b-button>
              </div>
            </div>
          </b-list-group-item>

          <b-list-group-item class="bg-transparent">
            <div class="row">
              <div class="col-12 text-left text-muted">
                <small>Верификация</small>
              </div>
              <div class="col-12 text-left">

                <span class="d-inline-block pt-2 user-verified-text" v-if="accountIdentifyStatus"
                  v-html="accountIdentifyStatus"></span>
                <br><small v-if="accountIdentifyStatusDesc" v-html="accountIdentifyStatusDesc"></small>

                <b-button name="confirmEmail" v-if="showIdentificationButton" class="red-btn float-right"
                  @click.prevent="confirmPassport">
                  Пройти верификацию
                </b-button>
              </div>
            </div>
          </b-list-group-item>

        </b-list-group>

      </div>
    </div>

    <ConfirmPassport :user-data="userData" v-if="userData.verified !== 1 && identificationEnabled"
      @changed="updateState()"></ConfirmPassport>
    <ConfirmEmail :user-data="userData"></ConfirmEmail>
    <ChangePassword :user-data="userData"></ChangePassword>

    <ConfirmPhoneViaCall :user-data="userData" v-if="mainSettings && mainSettings.phoneActionsMethod === 'call'">
    </ConfirmPhoneViaCall>
    <ConfirmPhone :user-data="userData" v-else></ConfirmPhone>
  </div>
  <div v-else class="col-12 col-lg-5 d-none d-lg-block px-lg-0
                    row justify-content-center w-100 color-red mx-0 h-100 py-4 text-center mx-auto">
    <i class="fa fa-spinner fa-spin fa-fw my-auto"></i>
  </div>
</template>

<script>
import ConfirmPhone from '@/components/user/actions/ConfirmPhone'
import ConfirmEmail from '@/components/user/actions/ConfirmEmail'
import ChangePassword from '@/components/user/actions/change-password/ChangePassword'
import ConfirmPhoneViaCall from '@/components/user/actions/ConfirmPhoneViaCall'
import ConfirmPassport from '@/components/user/actions/ConfirmPassport'
import Alert from '@/components/Alert'

export default {
  name: 'Profile',
  data() {
    return {
      loggingOut: false,
      identityData: null,
      accountIdentifyStatus: null,
      accountIdentifyStatusDesc: null,
      showIdentificationButton: false,
      updateNameResponse: null,
      usersName: ''
    }
  },
  components: {
    ConfirmPhone,
    ConfirmEmail,
    ChangePassword,
    ConfirmPhoneViaCall,
    ConfirmPassport,
    Alert
  },
  async created() {
    await this.$store.dispatch('fetchUserIdentityData')
  },
  computed: {
    identificationEnabled() {
      let settings = this.$store.getters.getMainSettings
      if (typeof settings !== "undefined") {
        if (settings.hasOwnProperty("identificationEnabled") && settings.identificationEnabled) {
          return true
        }
      }

      return false
    },
    setDates() {
      let date = new Date()
      let monthAgo = new Date(date)
      monthAgo.setDate(monthAgo.getDate() - 30)
      let dateOptions = { hour12: false, hour: "2-digit", minute: "2-digit", year: 'numeric', month: 'short', day: '2-digit' }
      date = new Date(date).toLocaleString('ru-RU', dateOptions)
      monthAgo = new Date(monthAgo).toLocaleString('ru-RU', dateOptions)

      return { now: date, monthAgo: monthAgo }
    },
    userData() {
      return this.$store.getters.userData
    },
    // wheelStats () {
    //   let stat = this.$store.getters.getWheelStats
    //   if (!stat) {
    //     this.$store.dispatch('fetchWheelStatistic')
    //   }
    //
    //   return this.$store.getters.getWheelStats
    // },
    userIdentityData() {
      return this.$store.getters.identityData
    },
    mainSettings() {
      return this.$store.getters.getMainSettings
    },
    userDataFetched() {
      return this.$store.getters.userDataFetched
    }
  },
  updated() {
    this.identityData = this.$store.getters.identityData
  },
  mounted() {
    this.identityData = this.$store.getters.identityData
    setTimeout(() => {
      this.updateState()
    }, 1000)
  },
  methods: {
    logout() {
      this.loggingOut = true
      this.axios.get(
        this.$_config.baseUrl + '/Api/logout'
      ).then(() => {
        this.$store.dispatch('removeUserData')
        this.$router.push({ name: 'home' })
      }).catch(err => {
        console.log(err)
      })
    },
    changePassword() {
      this.$root.$emit('bv::show::modal', 'changePasswordModal')
    },
    confirmPhone() {
      this.$root.$emit('bv::show::modal', 'confirmPhoneModal')
    },
    confirmEmail() {
      this.$root.$emit('bv::show::modal', 'confirmEmailModal')
    },
    confirmPassport() {
      this.$root.$emit('bv::show::modal', 'confirmPassportModal')
    },
    setAccountIdentifyStatus() {
      if (!this.userData) {
        return
      }
      let accountVerified = this.userData.verified
      let identifyConfirmedStatus = false

      if (this.identityData) {
        identifyConfirmedStatus = this.identityData.approved
      }
      this.showIdentificationButton = false
      this.accountIdentifyStatus = null

      switch (accountVerified) {
        case 0:
          this.showIdentificationButton = true
          this.accountIdentifyStatus = '<span class="text-danger">Аккаунт не верифицирован</span>'

          if (identifyConfirmedStatus === 2) {
            const reason = this.identityData.reject_reason
            this.accountIdentifyStatusDesc = `<span class="text-danger">Верификация отказано - ${reason}</span>`
          }
          if (identifyConfirmedStatus === 0) {
            this.showIdentificationButton = false
            this.accountIdentifyStatus = '<span class="text-warning">Данные в оброботке</span>'
          }
          break;
        case -1:
          this.showIdentificationButton = true
          this.accountIdentifyStatus = '<strong class="text-danger">Необхадимо пройти верификацию !</strong>'

          if (identifyConfirmedStatus === 0) {
            this.showIdentificationButton = false
            this.accountIdentifyStatus = '<span class="text-warning">Данные в оброботке</span>'
          }
          if (identifyConfirmedStatus === 2) {
            const reason = this.identityData.reject_reason
            this.showIdentificationButton = true
            this.accountIdentifyStatus = '<span class="text-warning">Получено запрос на верификацию</span>'
            this.accountIdentifyStatusDesc = `<span class="text-danger">Верификация отказано - ${reason}</span>`
          }
          break
        case 1:
          this.accountIdentifyStatus = '<span class="text-success">Аккаунт верифицирован</span>'
          break
      }
    },
    updateState() {
      this.identityData = this.$store.getters.identityData
      this.setAccountIdentifyStatus()
    },
    updateUsersName() {
      this.updateNameResponse = { status: 'loading' }
      let queryString = `name=${this.usersName}`
      this.axios.post(
        this.$_config.baseUrl + '/Api/updateUserName/', queryString,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(({ data }) => {
        this.updateNameResponse = data

        if (data.status !== "error") {
          this.$store.dispatch('fetchUserData')
          setTimeout(() => {
            this.updateNameResponse = null
          }, 2000)
        }
      }).catch(() => {
        this.updateNameResponse = {
          status: 'error', msg: 'Ошибка при отправке запроса, повторите позже'
        }
      })
    }
  },
  watch: {
    userDataFetched(newState) {
      if (newState) {
        if (!this.userData.login) {
          window.location.reload()
        }
        this.updateState()
      }
    },
    identityData: {
      handler: function (newValue) {
        if (newValue) {
          this.updateState()
        }
      },
      deep: true

    },
    userData: {
      handler: function (data) {
        if (data) {
          this.setAccountIdentifyStatus()
        }
      },
      deep: true
    }
  }
}
</script>

<style>

.profile-user-icon {
  height: 150px;
}

.profile-description {
  background-color: #3d3d3d;
}

.user-info .list-group-item {
  border: none;
  padding: 5px 0 !important;
  border-bottom: 1px solid #6a7075;
}

.wheel-access .progress {
  height: 25px;
  border-radius: 3px 25px;
  background-color: #00000017;
  box-shadow: 0 0 4px 1px #000 inset !important;
  position: relative;
}

.bg-wheel-progress {
  border-radius: 2px 25px;
  background: #b50220;
  background: -moz-linear-gradient(left, #b50220 0%, #c00912 28%, #c30b0f 58%, #ea2f05 100%);
  background: -webkit-linear-gradient(left, #b50220 0%, #c00912 28%, #c30b0f 58%, #ea2f05 100%);
  background: linear-gradient(to right, #b50220 0%, #c00912 28%, #c30b0f 58%, #ea2f05 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b50220', endColorstr='#ea2f05', GradientType=1);
}

.wheel-stat-value {
  position: absolute;
  width: 100%;
  text-align: center;
  height: 100%;
  font-size: 15px;
  top: 11px;
}

@media screen and (max-width: 768px) {
  .profile-user-icon {
    height: 80px;
  }

  .user-email-text,
  .user-phone-text {
    max-width: 55%;
    overflow: hidden;
    display: inline-block;
  }
}
</style>
