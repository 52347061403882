<template>
  <b-modal
    id="confirmEmailModal"
    title="Подтверждение почты"
    size="md"
    hide-footer
    modal-class="main-modal page-main-block"
    body-class="pb-2 pt-1 px-3 "
    centered
    header-class="text-white border-0 pb-0"
    title-class="text-center underlined-muted-title">
    <form
      v-if="userData && !emailSent"
      class="email-confirmation-form"
      @submit.prevent="confirmEmail">
      <div class="col-12 text-center mx-auto my-2">
        <small class="underlined-muted-title"> На вашу почту придёт письмо с инструкцией для смены пароля </small>
      </div>
      <b-form-group class="mb-4">
        <div class="col-12 d-inline-block">
          <label class="col-form-label white-color"><small>Адрес Вашей почты</small></label>
        </div>
        <div class="col-12 d-inline-block">
          <b-form-input
            v-model="payload.email"
            :class="__validate('email', payload.email)"
            required
            class="main-modal-input"
            type="email"/>
          <i class="fa input-hint"></i>
        </div>
      </b-form-group>

      <b-form-group>
        <div class="col-12 row mx-0 justify-content-between">
          <div class="col-6 col-sm-4 px-0">
            <captcha ref="email_confirmation" :captcha_key="'email_confirmation'" />
          </div>
          <div class="col-6 col-sm-8 px-0">
            <input
              v-model="payload.captcha"
              class="form-control main-modal-input"
              required>
            <small class="d-none d-md-block captcha-hint text-muted">
              Введите символы которые видите на картинке
            </small>
          </div>
        </div>
      </b-form-group>
    </form>

    <b-row class="w-100 mt-3 mx-auto col-12">
      <alert :message="response" />
    </b-row>

    <div v-if="!emailSent" class="form-group mt-3">
      <button
        name="submit"
        class="btn red-btn d-flex mx-auto px-4"
        :disabled="isSubmitting"
        @click.stop.prevent="sendConfirmationEmail">
        Отправить письмо
      </button>
    </div>
  </b-modal>
</template>

<script>
import captcha from '@/components/main/Captcha'
import Alert from '@/components/Alert'

export default {
  name: 'ConfirmEmail',
  data () {
    return {
      response: null,
      isSubmitting: false,
      emailSent: false,
      payload: {
        email: '',
        captcha: ''
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters.userData
    },
    isMobile () {
      return this.$_config.environment.mobile
    }
  },
  components: {
    Alert,
    captcha
  },
  methods: {
    sendConfirmationEmail () {
      this.response = { status: 'loading' }
      this.isSubmitting = true
      const queryString = `email=${this.payload.email}&email_confirmation_captcha=${this.payload.captcha}`
      this.axios.post(
        this.$_config.baseUrl + '/Api/EmailConfirmRequest/', queryString,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(({ data }) => {
        this.response = data
        this.payload.captcha = ''
        this.isSubmitting = false
        if (data.status && data.status === 'ok') {
          this.emailSent = true
          this.$store.dispatch('fetchUserData')
          setTimeout(() => {
            this.$root.$emit('bv::hide::modal', 'confirmEmailModal')
          }, 5000)
        }
      }).catch(() => {
        this.isSubmitting = false
        this.response = {
          status: 'error', msg: 'Ошибка при отправке запроса, повторите позже'
        }
      })
      this.$refs.email_confirmation.updateCaptcha()
    }
  },
  mounted () {
    if (this.userData) {
      this.payload.email = this.userData.email
    }
  }

}
</script>

<style scoped>
    .hint {
        font-size: 16px;
        color: black;
        font-weight: 600;
    }
</style>
