<template>
  <li class="col-10 col-xl-8 pull-right d-inline-block float-right">
    <div class="row mx-0 w-100 login-password-area justify-content-between">
      <div class="col-xs-11 col-lg-11 col-xl-11 px-0  justify-content-around header-signin-area ">
        <b-form
            v-if="!response"
            class=" login-form row mx-0 justify-content-around "
            @submit.prevent="Login">
          <div class="col-sm-10 col-lg-10 px-0">

            <div class="row mx-0 w-100">
              <div class="col-1  px-0 d-none d-lg-block">
            <span class="forgot-pass-area cursor-pointer"
                  title="Восстановление аккаунта"
                  @click="$root.$emit('bv::show::modal', 'recoverAccountModal')">
              <i class="fas fa-question-circle"></i>
            </span>
                <forgot-password></forgot-password>
              </div>
              <div class="col-12 col-lg-6 col-xl-5 pr-0 auth_input_area">
                <input
                    v-model="payload.login"
                    class="form-control header_auth_input"
                    id="header_auth_input"
                    required
                    type="text"
                    placeholder="Логин"
                    name="magnet_login">
                <label for="header_auth_input" class="p-0 m-0 wh-0 d-inline-block">Ваш логин</label>
              </div>
              <div class="col-12 col-lg-3 pr-0 auth_input_area pl-1">
                <input
                    v-model="payload.password"
                    class="form-control header_auth_input"
                    id="header_auth_input_1"
                    required
                    type="password"
                    name="magnet_password"
                    placeholder="Пароль">
                <label for="header_auth_input_1" class="p-0 m-0 wh-0 d-inline-block">Ваш пароль</label>
              </div>
            </div>




          </div>
          <div class="col-sm-2 col-lg-2 pl-0">
            <button name="isSubmitting"
                    class="btn auth-btn red-btn w-100"
                    :disabled="isSubmitting"
                    @click.prevent="Login">
              <i class="fas fa-sign-in-alt"></i>
            </button>
          </div>
        </b-form>
        <div v-else-if="response" class="col-12 head-auth-loader p-0 row mx-0 justify-content-end">
          <div class="col-11 mx-auto pl-0">
            <Loader :message="response"></Loader>
          </div>
          <div v-if="response&&!isSubmitting" class="col-1 pr-0 ">
            <button class="btn gray-btn float-right"
                    name="cancelResponse"
                    @click="cancelResponse">
              <i class="fa fa-redo"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="col-xs-1 col-lg-1 col-xl-1 px-0 h-100  header-reg-btn-area">
        <div class="mx-auto w-100" >
          <router-link class="btn auth-btn gray-btn d-block" :to="{ path: '/registration' }">
            <i class="fa fa-user-plus"></i>
          </router-link>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import Loader from '@/components/NarrowLoader'
import ForgotPassword from '@/components/user/actions/ForgotPassword'

export default {
  name: 'HeaderAuth',
  components: {
    Loader,
    ForgotPassword
  },
  data () {
    return {
      response: null,
      isSubmitting: false,
      payload: {
        login: '',
        password: ''
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters.userData
    }
  },
  methods: {
    cancelResponse () {
      this.response = null
    },
    Login () {
      this.response = { status: 'loading' }
      this.isSubmitting = true
      if (!this.payload.login || !this.payload.password) {
        this.isSubmitting = false
        this.response = { status: 'error', msg: 'Пожалуйста, заполните все поля' }
        setTimeout(() => {
          this.response = null
        }, 2500)
        return false
      }
      this.axios.post(
        this.$_config.baseUrl + '/Api/Login', {
          authorize: this.payload
        }
      ).then((userData) => {
        const data = userData.data
        this.isSubmitting = false
        this.response = data
        if (data.status === 'ok') {
          this.$store.dispatch('fetchUserData')
          this.response = { status: 'ok', msg: 'Добро пожаловать' }
          this.$store.dispatch('fetchMessages')
          // this.$store.dispatch('fetchWheelStatistic')
          // this.$store.dispatch('fetchGames')
          this.$store.dispatch('mainSettings')

          if (this.userData) {
            this.$router.push({ name: 'home' })
          }
        }
        setTimeout(() => {
          this.response = null
        }, 2500)
      }).catch((err) => {
        if (err) {
          this.isSubmitting = false
          this.response = { status: 'error', msg: err.response.data.errors }
          setTimeout(() => {
            this.response = null
          }, 2500)
        }
      })
    }
  },
  mounted () {
    if (this.userData) {
      this.$router.push({ name: 'home' })
    }
  },
  watch: {
    userData (newValue) {
      if (newValue) {
        setTimeout(() => {
          this.$router.push({ path: '/' })
        }, 500)
      }
    }
  }
}
</script>

<style>
    .auth_input_area{
      height: 33px;
      overflow: hidden;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus{
        box-shadow: 0 0 0 30px #2e2e2e inset !important;
        padding-left: 0 !important;
        -webkit-text-fill-color: #ffffff;
        line-height: 30px;
        border-bottom: 1px solid #737373;
    }
    .alert-primary{
        background-color: transparent !important;
    }
    .header_auth_input:-webkit-autofill,
    .header_auth_input:-webkit-autofill:focus,
    .header_auth_input:-webkit-autofill:hover
    {
      padding: 5px 1px 0 0 !important;
      height: auto;
      border: none;
      margin: 0;
      line-height: 27px;
      border-radius: 3px;
      box-shadow: 0 0 0 33px #0b1112 inset !important;
      color: #ffffff;
      webkit-text-fill-color: #ffffff;
      border-bottom: 1px solid #242424 !important;
      outline: none !important;
      font-size: 12px;
    }
    .header_auth_input:autofill,
    .header_auth_input:autofill:focus,
    .header_auth_input:autofill:hover
    {
      box-shadow: 0 0 0 33px #2c2c2c00 inset !important;
    }

</style>
<style scoped>
    .forgot-pass-area{
        display: block;
        width: 28px;
        height: 28px;
        text-align: center;
        border-radius: 50px;
        margin: auto;
    }
    .forgot-pass-area i {
      font-size: 24px;
      color: white;
      font-weight: 100;
      line-height: 34px;
      margin-right: 2px;
    }

    @media (min-width: 320px) and (max-width: 1199px) {
      .login-password-area {
        display: none;
      }
    }
</style>
