<template>
  <div class="row mx-0 slider-container h-100">
    <b-carousel :interval="6000" indicators>
      <b-carousel-slide v-for="(slide, index) in slides" :key="index"
        v-lazy-container="{ selector: 'img', loading: require('@/../public/images/icons/small-loader.svg') }">
        <img slot="img" class="main-slide-img" :data-src="slide" :src="slide" @click="onSlideClick(index)"
          alt="Online Casino - Zevs777">
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: 'Slider',
  data() {
    return {
      slides: {
        slide_1: require('@/../public/images/banner/banner_1.png'),
        slide_2: require('@/../public/images/banner/banner_2.png'),
        slide_3: require('@/../public/images/banner/banner_3.png'),
      }
    }
  },
  methods: {
    onSlideClick(index) {
      if (index === 'tg') {
        window.open('https://telegram.im/magnetcasino', '_blank');
      }
    }
  }
}
</script>

<style>
.main-slide-img {
  margin-top: 80px;
  max-width: 100vw;
  width: 100vw;
  height: calc(100vw/4.8);
}

.slider-home-container img[lazy='loading'] {
  object-fit: none !important;
}

.slider-container>div {
  width: 100%;
}

.carousel-indicators li {
  border-radius: 50%;
  height: 8px !important;
  width: 8px !important;
  border: none !important;
  background: #f1f1f1 !important;
  margin-right: 7px !important;
  margin-left: 7px !important;
}

.carousel-indicators li.active {
  background-color: #950101 !important;
}

@media screen and (max-width: 500px) {
  .slider-home-container .carousel-indicators {
    display: none !important;
  }

  .slider-home-container img[lazy='loading'] {
    object-fit: contain !important;
  }
}
</style>
