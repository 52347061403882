<template>
  <div class="withdraw-container py-2 py-lg-4 col-12 col-lg-8  mx-auto mb-5">
    <div class="col-12 page-router ">
      <h6 class="text-white w-100 text-center">
        Главная /
        <span class="active-router-text">Выплата</span>
      </h6>
    </div>
    <h4 class="w-100 text-center text-white my-3 my-lg-5 page-main-title">
      Выплаты
    </h4>
    <div id="withdraw-area" class="col-12 mx-auto page-main-block withdraw-main-area py-3 px-0 mt-5">
      <div v-if="userData" class="col-12 px-0 last-payout-area">
        <div class="col-12 col-lg-9 mx-auto  pt-2 pb-4 py-lg-4 px-0">
          <h6 v-if="withdrawHistory" class="text-center underlined-muted-title mb-2 mb-lg-4 pb-2 mx-auto d-table">
            Показаны последние {{ withdrawHistory.count }} выводы
          </h6>
          <div class="col-12 px-0 last-withdraws position-relative">
            <b-carousel v-if="withdrawHistory" :interval="4000" style="width: 100%" controls indicators>
              <b-carousel-slide v-for="(withdraw, index) in withdrawHistory.withdraws" :key="index">
                <div class="col-12 text-white row mx-0 justify-content-between px-0 px-lg-5">
                  <p class="text-white m-0 w-100 text-center">
                    {{ withdraw.comment }}
                    {{ withdraw.amount }} в {{ withdraw.date }}
                    - {{ withdraw.status }}
                  </p>
                </div>
              </b-carousel-slide>
            </b-carousel>
          </div>
        </div>
      </div>
      <div class="col-12 mx-auto withdraw-methods-area px-0 py-2 py-lg-0">
        <div v-if="withdrawMethods.length" class="row justify-content-between px-0 mx-0">
          <h6 v-if="!userData" class="w-100 text-center page-gray-title mb-4 mt-2">
            Способы вывода
          </h6>
          <div class="col-12 px-0 withdraw-methods row mx-0 mb-3 mb-sm-0"
            :class="[userData ? 'col-md-6 col-lg-6' : 'mx-auto  mt-4 mb-md-1 justify-content-between']">
            <div v-for="method in withdrawMethods" :key="method.id" class="col-6 px-0 mb-3 mt-3"
              @click.prevent="changeMethod(method)">
              <div class="withdraw-method row justify-content-between"
                :class="method.type === 'piastrixwallet' ? 'start-selected' : ''">
                <div class="col-12 px-0 method-img-area" :class="[!userData ? 'mx-auto' : '']"
                  v-lazy-container="{ selector: 'img', loading: require('@/../public/images/icons/game-images-loader.svg') }">
                  <img :src="$config.baseUrl + method.img" :data-src="$config.baseUrl + method.img"
                    class="withdraw-method-image" :alt="'MagnetBet выплата ' + method.name">
                </div>
                <div v-if="userData" class="px-0 withdraw-check-area text-center m-auto">
                  <div class="withdraw-method-check"
                    :class="[method.name === selectedMethod.name ? 'selected-method' : '']">
                    <span v-if="selectedMethod.name === method.name">
                      <i class="fa fa-check"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="userData" class="col-12 col-md-6 px-0">
            <div class="row mx-0 withdraw-form-area">
              <div class="col-12 my-0 chosen-method-title row mx-0 justify-content-between p-0">
                <div v-if="selectedMethod.name" class="col-12 col-sm-9 col-lg-9 my-0 chosen-method-title">
                  <!--                  {{selectedMethod}}-->
                  <span class="text-white">Выбранный метод: {{ selectedMethod.name }}</span>
                </div>
                <div v-else class="col-9 my-0 chosen-method-title">
                  <div class="col-6">
                    Выберите метод !
                  </div>
                </div>
                <div class="col-3 p-0 text-center withdraw-money-icon-area d-none d-sm-block">
                  <img :src="moneyIcon" alt="MagnetBet withdraw balance" class="py-0 py-lg-2 mx-auto d-inline-block">
                </div>
              </div>

              <b-form v-if="selectedMethod.name !== ''" class="withdraw-form my-4 col-12">
                <div class="row w-100 mx-0">
                  <div v-if="selectedMethod.name" class="chosen-method-info mb-3">
                    <span class="hint-amount gray-color">Минимальная сумма выплаты {{ selectedMethod.min_amount }}
                      руб.</span><br>

                    <span class="hint-amount gray-color">
                      Максимальная сумма выплаты {{ selectedMethod.max_amount }} руб. за один перевод.<br>
                    </span>
                    <span class="hint-amount gray-color">
                      Вывод происходит обычно до 30 минут.
                    </span>

                    <span class="hint-amount gray-color"><br><br>
                      Уважаемый Игрок, максимальная сумма вывода за день составляет 50.000руб! Остаток выигрыша можете
                      выводить в последующие дни!
                    </span><br>

                    <span>
                      {{ selectedMethod.add_info }}
                    </span><br><br>

                  </div>

                  <div class="input-group mb-2 mb-lg-4">
                    <div class="input-group mb-2">
                      <div class="col-12 px-0">
                        <input id="withdraw-amount" v-model="payload.amount" class="form-control main-input" type="text"
                          :min="selectedMethod.min_amount" :max="selectedMethod.max_amount"
                          placeholder="Введите сумму вывода" v-on:keyup="keyUp" required>
                      </div>
                      <span class="hint-amount" v-if="withdrawinfo" style="margin-top: 10px">{{ withdrawinfotext }}</span>
                    </div>
                  </div>

                  <div class="input-group mb-2 mb-lg-4" v-if="selectedMethod.type == 'auris_ALL'">
                    <div class="input-group mb-2">
                      <div class="col-12 px-0">
                        <input id="withdraw-bank" v-model="payload.bank" class="form-control main-input" type="text"
                          :min="selectedMethod.min_amount" :max="selectedMethod.max_amount"
                          placeholder="Введите название банка">
                      </div>
                    </div>
                  </div>

                  <div class="input-group mb-2 mb-lg-4">
                    <div class="input-group">
                      <div class="col-12 px-0">
                        <input v-model="payload.recipient" v-mask="getSelectedMethodMask" class="form-control main-input"
                          type="text"
                          :placeholder="selectedMethod.name === '' ? 'Выбеите способ выплаты' : 'Введите ' + selectedMethod.requisites_title">
                      </div>
                    </div>
                  </div>

                  <div class="row w-100 mx-0">
                    <Alert :message="formResponse"></Alert>
                  </div>

                  <b-row class="mt-2 w-100 mx-0">
                    <div class="col-12 col-lg-7 mx-auto px-0">
                      <div class="green-btn-bg">
                        <button name="openModal" class="btn red-btn w-100 text-white" :disabled="isSubmitting"
                          @click.stop.prevent="openModal()">
                          Вывести деньги
                        </button>
                      </div>
                    </div>
                  </b-row>
                </div>
                <!--                <div class="row w-100 mx-0" v-else>-->
                <!--                  <div v-if="selectedMethod.name" class="chosen-method-info mb-3">-->
                <!--                    <span class="hint-amount gray-color">Минимальная сумма выплаты: <br>-->
                <!--                      - Qiwi Кошелек: 300 руб <br>-->
                <!--                      - Visa/MasterCard: 500 руб-->
                <!--                    </span>-->
                <!--                    <br><br>-->
                <!--                    <span class="hint-amount gray-color">Максимальная сумма выплаты {{ selectedMethod.max_amount }} руб за один перевод.</span><br>-->
                <!--                    <span class="hint-amount gray-color">Вывод происходит в ручном режиме и занимает до 5 минут.</span>-->
                <!--                    <br><br>-->
                <!--                    <span class="hint-amount gray-color">Для снятия средств обратитесь в службу поддержки Телеграмм-->
                <!--                      <a href="https://t.me/vpayfin" target="_blank" class="text-dark-orange"><strong>@vpayfin</strong></a>-->
                <!--                    </span>-->
                <!--                  </div>-->
                <!--                </div>-->
              </b-form>
            </div>
          </div>
        </div>
        <div v-else class="row justify-content-center w-100 color-red mx-0 h-100 py-4">
          <i class="fa fa-spinner fa-spin fa-fw my-auto"></i>
        </div>
      </div>
    </div>
    <b-modal v-if="selectedMethod" id="confirmWithdrawModal" v-model="confirmWithdrawModal" size="md"
      modal-class="main-modal" :hide-header="true" footer-border-variant="gray">
      <div class="col-12 px-0 text-center">
        <span class=""> {{ hint }} </span>

        <div class="row mx-0 my-3">
          <Alert :message="popupMessages"></Alert>
        </div>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }" class="border-0">
        <b-button name="makeWithdraw" size="sm" class="btn red-btn text-white" @click.prevent="makeWithdraw()">
          Вывести
        </b-button>
        <b-button name="cancel" size="sm" class="gray-btn" @click="cancel()">
          Отмена
        </b-button>
      </template>
    </b-modal>

    <b-modal v-if="userData" id="withdrawHint" ref="withdrawHint" size="md" modal-class="main-modal page-main-block"
      ok-title="Закрыть" ok-only ok-variant="success modal-ok" header-class="text-white border-0"
      header-close-variant="orange" title-class="text-center underlined-muted-title" footer-class="border-0" hide-footer
      body-class="pt-0" centered>
      <div class="row mx-0 col-12 mx-auto px-0 px-md-3">
        <span>
          Уважаемые Клиенты, со сегодняшнего дня из-за небольших тех. неисправностей метод выводов через Qiwi кошелек и
          банковские карты
          <u>временно</u>
          будут не доступны! Пожалуйста выводите пока через кошельки FKWallet или Piastrix!
        </span> <br>
        <small>
          <i class="fa fa-info-circle mr-1 fs-16"></i>
          Если не знаете как создать и использовать данные кошельки, за инструкцией можете обратиться к
          <a href="https://t.me/magnetcasinomanager" target="_blank" rel="noopener"
            class="text-decoration-none text-white font-weight-bold"> менеджеру </a>
          или в
          <a href="https://t.me/vpayqiwi2" target="_blank" rel="noopener"
            class="text-decoration-none text-white font-weight-bold">
            техподдержку сайта!
          </a>
        </small>
      </div>
    </b-modal>

  </div>
</template>

<script>
import Alert from '@/components/Alert'

const moneyIcon = require('@/../public/images/icons/payment/payout.png')

export default {
  name: 'Withdraw',
  data() {
    return {
      currentPath: null,
      moneyIcon,
      payload: {
        recipient: '',
        amount: '',
        name: '',
        bank: ''
      },
      selectedMethod: {
        name: '',
        mask: '',
        min_amount: 0,
        max_amount: 0,
        type: '',
        add_info: ''
      },
      isSubmitting: false,
      modalConfirmed: false,
      confirmWithdrawModal: false,
      formResponse: {},
      popupMessages: {},
      hint: '',
      withdrawinfo: false,
      withdrawinfotext: ''
    }
  },
  components: {
    Alert
  },
  computed: {
    getSelectedMethodMask() {
      return typeof this.selectedMethod.mask === 'string' ? this.selectedMethod.mask : this.selectedMethod.mask.join(',').split(',')
    },
    withdrawMethods() {
      if (!this.$store.getters.getWithdrawMethods.length) {
        this.$store.dispatch('fetchAvailableWithdrawMethods')
      }
      return this.$store.getters.getWithdrawMethods
    },
    withdrawHistory() {
      return this.$store.getters.getWithdrawHistory
    },
    userData() {
      return this.$store.getters.userData
    },
    isMobile() {
      return this.$_config.isMobile()
    }
  },
  methods: {
    changeMethod(method) {
      this.withdrawinfo = false
      if (!this.userData) {
        this.$root.$emit('bv::show::modal', 'loginModal')
        return this.$root.$emit('reasonToLogin', 'Для вывода средств нужно авторизоваться')
      }

      this.selectedMethod = method
      this.formResponse = {}

      setTimeout(() => {
        const amountInput = document.getElementById('withdraw-amount')
        if (amountInput) {
          amountInput.focus()
        }
      }, 100)

      if (this.isMobile) {
        document.querySelector('#withdraw-amount').scrollIntoView({ behavior: "smooth" })
      }
    },
    makeWithdraw() {
      if (!this.userData) {
        return
      }
      this.formResponse = { status: 'loading' }
      this.popupMessages = { status: 'loading' }
      this.isSubmitting = true
      this.axios.post(
        this.$_config.baseUrl + '/Api/newWithdraw',
        {
          withdraw_amount: this.payload.amount,
          withdraw_requisites: this.payload.recipient,
          method: this.selectedMethod.type,
          bank: this.payload.bank
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(({ data }) => {
        if (data.status === 'ok') {
          this.popupMessages = data
          this.formResponse = {}
          this.payload.amount = null
          this.payload.recipient = null
          setTimeout(() => {
            this.$root.$emit('bv::hide::modal', 'confirmWithdrawModal')
          }, 4000)

        } else {
          this.confirmWithdrawModal = false
          this.formResponse = data
        }
        this.isSubmitting = false
      }).catch(err => {
        console.log(err)
      })
      return true
    },
    openModal() {
      this.formResponse = {}
      this.popupMessages = {}
      if (!this.$store.getters.userData) {
        this.$root.$emit('bv::show::modal', 'loginModal')
        return this.$root.$emit('reasonToLogin', 'Для вывода средств нужно авторизоваться')
      }

      if (!this.selectedMethod.name) {
        this.formResponse = { status: 'error', msg: 'Пожалуйста выберите платежную систему' }
        return false
      }

      if (!this.payload.amount || this.payload.amount != parseFloat(this.payload.amount)) {
        this.formResponse = { status: 'error', msg: 'Пожалуйста, укажите правильую сумму' }
        return false
      }


      if (this.selectedMethod.type == "auris_ALL") {
        var regs = /^[A-Za-z0-9а-яА-Я]{3,30}$/
        if (!regs.test(this.payload.bank)) {
          this.formResponse = { status: 'error', msg: 'Название банка - неправильный формат' }
          return false
        }
      }

      if (this.payload.amount < this.selectedMethod.min_amount) {
        this.formResponse = {
          status: 'error',
          msg: 'Минимальная сумма для выбранного метода  ' + this.selectedMethod.min_amount
        }
        return false
      }

      if (this.payload.amount > this.selectedMethod.max_amount) {
        this.formResponse = { status: 'error', msg: 'Максимальная сумма для снятия ' + this.selectedMethod.max_amount }
        return false
      }

      if (!this.payload.recipient && this.selectedMethod.name != 'Аndroid') {
        this.formResponse = { status: 'error', msg: 'Заполните реквизты.' }
        return false
      }
      this.confirmWithdrawModal = true

      if (this.payload.recipient) {
        this.hint = 'Подтвердите вывод на ' + this.payload.recipient + ' на сумму ' + this.payload.amount + 'Руб'
      } else {
        this.hint = 'Подтвердите вывод на сумму ' + this.payload.amount + 'Руб'
      }
    },
    keyUp(evt) {
      this.withdrawinfo = false
      if (this.selectedMethod.type == "visacardpercent" && evt.target.value >= this.selectedMethod.min_amount) {
        this.withdrawinfo = true
        this.withdrawinfotext = "Поступят " + (evt.target.value - 50) + " руб, 50 руб комиссия"
      }
    }
  },
  mounted() {
    this.$store.dispatch('fetchWithdrawHistory')

    if (this.$_config.isMobile()) {
      window.scrollTo({
        top: 0
      })
    }

    setTimeout(() => {
      const withdrawMethod = document.querySelector('.start-selected')
      if (withdrawMethod && this.userData) {
        // withdrawMethod.click()
      }
    }, 500)
  }
}
</script>

<style>
html {
  scroll-behavior: smooth;
}

.last-payout-area {
  border-bottom: 1px solid #171717;
}

.withdraw-form-area {
  background-color: #3d3d3d;
}

.chosen-method-title {
  width: 100%;
  background-color: #000;
  padding: 20px 10px;
}

.withdraw-container .carousel-caption {
  position: relative !important;
  left: 0 !important;
  width: 100%;
  -webkit-box-pack: justify !important;
  justify-content: space-between !important;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  bottom: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  text-align: left !important;
}

.withdraw-container .carousel-control-prev,
.withdraw-container .carousel-control-next {
  width: unset !important;
  z-index: 19 !important;
}

.withdraw-methods {
  height: max-content;
}

@media screen and (max-width: 576px) {
  .withdraw-methods>div {
    align-self: center !important;
  }
}

@media screen and (max-width: 992px) {
  .withdraw-method {
    margin: 0 5px !important;
  }

  .withdraw-methods-area {
    display: flex;
    justify-content: space-between;
  }

  .withdraw-container .carousel-control-prev,
  .withdraw-container .carousel-control-next {
    display: none !important;
  }

  .withdraw-container .carousel-indicators {
    bottom: -35px !important;
  }

  .withdraw-check-area {
    position: absolute !important;
    bottom: 0;
    right: 5px;
  }

  .withdraw-method-check {
    margin: 0 !important;
    float: right;
  }

  .withdraw-method-image {
    height: 28px !important;
  }
}

@media screen and (min-width: 992px) {
  .withdraw-container .carousel-indicators {
    display: none !important;
  }
}
</style>
<style scoped>
.fs {
  font-size: 16px;
}

.withdraw-check-area {
  position: absolute;
  right: 0;
  bottom: 0;
}

.withdraw-method-check {
  width: 20px;
  height: 20px;
  background-color: #363636;
  margin: auto;
  box-shadow: 1px 1px 2px 1px inset #292929 !important;
  border-radius: 1px;
}

.choose-method-txt {
  font-size: 14px;
}

.withdrows {
  max-height: 400px;
  overflow-y: scroll;
}

.withdrows::-webkit-scrollbar {
  width: 3px;
}

.withdrows::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#f26e10, #ffb635);
}

.last-withdraws-container {
  width: 100%;
  background-color: rgba(36, 55, 98, 0.2);
  border-radius: 10px;
  max-height: 500px;
  overflow-y: scroll;
}

.last-withdraws-container::-webkit-scrollbar {
  width: 3px;
}

.last-withdraws-container::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#f26e10, #ffb635);
}

.withdraw-history,
.hint-withdraw-method {
  font-size: 18px;
  font-weight: 600;
}

.hint-amount {
  font-size: 14px;
}

.withdraw-form input {
  border-radius: 30px;
}

.last-withdraw-item {
  font-size: 14px;
}

.last-withdraw-item hr {
  background-color: #132835;
}

.withdraw-method {
  background-color: #3d3d3d;
  border-radius: 3px;
  margin: 0 15px;
  cursor: pointer;
  max-height: 110px;
  position: relative;
}

.withdraw-method-image {
  width: 80%;
  margin: auto;
  height: 60px;
  object-fit: contain;
}

.method-img-area {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 8px 0;
  text-align: center;
  display: flex;
}

.withdraw-currency-area span {
  font-weight: 600;
  color: #6b7f8e;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.withdraw-currency-area {
  background-color: #e7e9eb;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  line-height: 75px;
  transition: 1.5s;
}

.selected-method {
  background-color: #980019;
  text-align: center;
  line-height: 20px;
}

.selected-method span {
  color: white;
  font-size: 13px;
}
</style>


<style scoped>
.page-router {
  margin-top: 100px;
}
</style>